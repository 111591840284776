import { useWallet } from '@solana/wallet-adapter-react'
import { CHAIN, TonProofItemReplySuccess, toUserFriendlyAddress, useTonConnectUI } from '@tonconnect/ui-react'
import { WalletType } from 'config/types/wallet'
import { ethers } from 'ethers'
import { useTranslation } from 'react-i18next'
import { TonProof } from 'services/types'
import { SentryHelper } from 'utils/sentryHelper'
import { HunnyToast } from 'utils/toastify'
import { useSignMessage as useWagmiSignMessage } from 'wagmi'

type SignMessageResponse = {
  messageSignature?: string
  tonProof?: TonProof
} & SignMessageTonResponse

type SignMessageTonResponse = { returnedTonAddress?: string }

const useSignMessage = (type: WalletType): ((message: string) => Promise<SignMessageResponse>) => {
  const { signMessage: solSignMessage } = useWallet()
  const { t } = useTranslation()
  const { signMessageAsync } = useWagmiSignMessage()
  const [tonConnectUI] = useTonConnectUI()

  const _handleEtherWalletSignMessage = async (_message: string): Promise<SignMessageResponse> => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<SignMessageResponse>(async (resolve) => {
      try {
        const signMessage = await signMessageAsync({
          message: _message,
        })

        if (signMessage) {
          return resolve({
            messageSignature: signMessage,
          })
        }
      } catch (error: any) {
        if (error?.code === -32603 || error?.code === -32000 || error?.code === 4001) {
          HunnyToast.warn(
            t('Rejected'),
            t('The request to sign the message on your wallet was rejected. Please try again.'),
            {
              toastId: 'sign-message-failed',
            },
          )
        } else {
          HunnyToast.error(t('Error'), error.message, {
            toastId: 'sign-message-failed',
          })
        }
      }
      resolve({
        messageSignature: '',
      })
    })
  }

  const _handleSolWalletSignMessage = async (inputMessage: string): Promise<SignMessageResponse> => {
    try {
      const message = new TextEncoder().encode(inputMessage)
      const signature = await solSignMessage(message)

      if (signature) {
        const signatureTextString = ethers.utils.hexlify(signature)
        return { messageSignature: signatureTextString }
      }
    } catch (error) {
      HunnyToast.warn(
        t('Rejected'),
        t('The request to sign the message on your wallet was rejected. Please try again.'),
        {
          toastId: 'sign-message-failed',
        },
      )

      SentryHelper.captureFeatureClientError({
        feature: 'SolanaConnector',
        error,
        options: { title: 'Solana sign message error' },
      })
      return null
    }
  }

  const handleTonWalletDisconnect = async () => {
    try {
      await tonConnectUI.disconnect()
    } catch {}
  }

  const _handleTonWalletSignMessage = async (inputMessage: string): Promise<SignMessageResponse> => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      try {
        const wallet = tonConnectUI.wallet
        await handleTonWalletDisconnect()

        let unSubcribeWalletModal = null

        if (wallet) {
          unSubcribeWalletModal = tonConnectUI.onSingleWalletModalStateChange((state) => {
            if (state.status === 'closed' && state.closeReason === 'action-cancelled') {
              resolve({ tonProof: null, returnedTonAddress: '' })
              unSubcribeWalletModal()
            }
          })
        } else {
          unSubcribeWalletModal = tonConnectUI.onModalStateChange((state) => {
            if (state.status === 'closed' && state.closeReason === 'action-cancelled') {
              resolve({ tonProof: null, returnedTonAddress: '' })
              unSubcribeWalletModal()
            }
          })
        }

        const unsubscribe = tonConnectUI.onStatusChange(async (wallet) => {
          const address = toUserFriendlyAddress(wallet?.account?.address, wallet?.account?.chain === CHAIN.TESTNET)

          const proof: TonProofItemReplySuccess['proof'] | null =
            (wallet?.connectItems?.tonProof as TonProofItemReplySuccess)?.proof ?? null
          if (!proof || !address || !wallet) {
            await handleTonWalletDisconnect()
            resolve({ tonProof: null, returnedTonAddress: '' })
            return
          }
          unsubscribe()
          resolve({
            tonProof: {
              tonNetwork: Number(wallet.account.chain),
              stateInit: wallet.account.walletStateInit,
              proof,
            },
            returnedTonAddress: address,
          })
        })

        tonConnectUI.setConnectRequestParameters({
          state: 'ready',
          value: {
            tonProof: inputMessage,
          },
        })

        if (wallet) {
          await tonConnectUI.openSingleWalletModal(wallet.device.appName)
        } else {
          await tonConnectUI.openModal()
        }
      } catch (error) {
        SentryHelper.captureFeatureClientError({
          feature: 'TonConnector',
          error,
          options: { title: 'Ton sign message error' },
        })
        return null
      }
    })
  }

  if (type === WalletType.EVM) {
    return _handleEtherWalletSignMessage
  }
  if (type === WalletType.SOL) {
    return _handleSolWalletSignMessage
  }
  if (type === WalletType.TON) {
    return _handleTonWalletSignMessage
  }
}

export default useSignMessage
