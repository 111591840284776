import Button from 'UIKit/Button'
import Modal, { ModalBody, ModalProps } from 'UIKit/Modal'
import Text from 'UIKit/Text'
import useDebounceCallback from 'hooks/useDebounceCallback'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ConfirmUnLinkTelegramModal: React.FC<
  ModalProps<{
    onDisconnect: () => Promise<boolean>
  }>
> = ({ onDismiss, data: { onDisconnect } }) => {
  const { t } = useTranslation()
  const debounceCb = useDebounceCallback()
  const handleDisconnect = () => {
    debounceCb(async () => {
      if (onDisconnect) {
        const isSuccess = await onDisconnect()
        if (isSuccess) {
          onDismiss()
        }
      }
    }, 200)
  }

  return (
    <Modal
      minWidth={['100% !important', '', '440px !important']}
      maxWidth={['100% !important', '', '440px !important']}
      onDismiss={onDismiss}
    >
      <ModalBody p="24px">
        <Text bold>{t('Disconnect Telegram account')}</Text>

        <Text fontWeight={300} lineHeight={1.2} my="24px" small color="textTertiary">
          {t('You are about to disconect the Telegram account, are you sure about that?')}
        </Text>

        <Button mx="20px" style={{ borderRadius: '4px' }} onClick={() => handleDisconnect()} variant="error">
          <Text bold small>
            {t('Yes, Disconnect')}
          </Text>
        </Button>

        <Button onClick={onDismiss} variant="text">
          <Text small color="textSubtle">
            {t('No, Keep Telegram account')}
          </Text>
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmUnLinkTelegramModal
