import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Button from 'UIKit/Button'
import { ButtonProps } from 'UIKit/Button/types'
import CircleLoader from 'UIKit/Loader/CircleLoader'
import Text from 'UIKit/Text'
import { USER_LOCKED_WITHDRAW_ERROR_CODE } from 'config/constants/server'
import { Token } from 'config/types'
import useModal from 'hooks/useModal'
import { useRequest } from 'hooks/useRequest'
import useSignMessage from 'hooks/useSignMessage'
import { useDisconnectTon, useIsTonConnectFeatured } from 'hooks/useTonConnect'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PaymentService from 'services/PaymentService'
import { TonProof } from 'services/types'
import { useWalletInfo } from 'state/auth/hooks'
import { useUserSettings } from 'state/profile/hooks'
import { Icons } from 'svgs'
import theme from 'theme'
import { colors } from 'theme/colors'
import { formatUsername } from 'utils/stringHelper'
import TonConnectSignWallet from 'views/Authentication/components/TonConnectSignWalletModal'
import ProvideTotpAuthCodeModal from 'views/Profile/Settings/components/SettingSecurity/2FA/ProvideTotpAuthCodeModal'
import WithdrawLockingAnnounceModal from '../WithdrawLockingAnnounceModal'

type WalletWithdrawButtonProps = {
  selectedToken: Token
  value: string
  validateAll: () => Promise<boolean>
  onSuccess: (txnId: string) => void
  toAddress: string
  memo: string
  onWithdrawLocked?: () => void
}

const WalletWithdrawButton: React.FC<WalletWithdrawButtonProps & ButtonProps> = ({
  selectedToken,
  validateAll,
  value,
  onSuccess,
  disabled,
  toAddress,
  memo,
  ...props
}) => {
  const disconnectTon = useDisconnectTon()
  const [onPresentTonConnectSignWallet, onDismissTonConnectSignWalletModal] = useModal(
    TonConnectSignWallet,
    'ton-wallet-connect-modal',
  )
  const isTonConnected = useIsTonConnectFeatured()

  const { onConnectByWalletType, walletInfo, wallet, isWrongAddress, account } = useWalletInfo()
  const { t } = useTranslation()

  const signMessage = useSignMessage(wallet.type)

  const { execute } = useRequest()

  const [submiting, setSubmiting] = useState<boolean>(false)

  const [onPresentProvideTotpCodeModal] = useModal(ProvideTotpAuthCodeModal)
  const [onPresentWithdrawLockModal] = useModal(WithdrawLockingAnnounceModal, 'withdraw_lock_modal')

  const {
    userSettings: { isActive2FA, isEnable2FAForWithdraw },
  } = useUserSettings()

  const handleWithdraw = async ({
    messageSignature,
    acceptMessage,
    totpAuthCode,
    tonProof,
  }: {
    messageSignature: string
    acceptMessage: string
    totpAuthCode?: string
    tonProof?: TonProof
  }) => {
    const withdrawResponse = await PaymentService.walletWithdraw({
      messageSignature,
      acceptMessage,
      totpAuthCode,
      tonProof,
    })
    if (withdrawResponse?.data) {
      onSuccess(withdrawResponse.data)
    }
    return withdrawResponse
  }

  const handlePrepareWithdraw = async () => {
    setSubmiting(true)

    const isValid = await validateAll()
    if (!isValid) {
      setSubmiting(false)
      return
    }

    const prepareData = await execute(
      PaymentService.prepareWalletWithdraw(value, toAddress || wallet.address, selectedToken, memo),
    )

    if (prepareData.code === USER_LOCKED_WITHDRAW_ERROR_CODE) {
      setSubmiting(false)
      return onPresentWithdrawLockModal({ countdownTime: prepareData.data.withdrawLockTime })
    }

    if (!prepareData.data) {
      setSubmiting(false)
      return
    }

    const { messageSignature, tonProof, returnedTonAddress } = await signMessage(prepareData.data.acceptMessageText)
    if (returnedTonAddress && wallet.address && returnedTonAddress !== wallet.address) {
      setSubmiting(false)
      return
    }

    if (!(messageSignature || tonProof)) {
      setSubmiting(false)
      return
    }

    if (isActive2FA && isEnable2FAForWithdraw) {
      onDismissTonConnectSignWalletModal()
      onPresentProvideTotpCodeModal({
        onTotpAuthCodeConfirmed: async (code) => {
          const response = await handleWithdraw({
            messageSignature,
            acceptMessage: prepareData.data.acceptMessage,
            tonProof,
            totpAuthCode: code,
          })
          return response
        },
      })
    } else {
      handleWithdraw({
        messageSignature,
        acceptMessage: prepareData.data.acceptMessage,
        tonProof,
      })
      onDismissTonConnectSignWalletModal()
    }

    setSubmiting(false)
  }

  if (isTonConnected) {
    return (
      <Button
        disabled={submiting || disabled}
        onClick={async () => {
          await disconnectTon()
          onPresentTonConnectSignWallet({ onWalletSigned: handlePrepareWithdraw })
        }}
        {...props}
      >
        {submiting && (
          <Box mr="12px">
            <CircleLoader />
          </Box>
        )}

        <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
          <Trans>Withdraw</Trans>
        </Text>
      </Button>
    )
  }

  if (!account) {
    return (
      <Button
        {...props}
        onClick={() => {
          onConnectByWalletType({ ignoreSignInSystem: true })
        }}
      >
        <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
          <Trans>Connect Wallet</Trans>
        </Text>
      </Button>
    )
  }

  if (isWrongAddress) {
    return (
      <>
        <Flex
          alignItems="center"
          border={`1px solid ${colors.warning}`}
          padding="12px"
          borderRadius={theme.radii.small}
          {...props}
        >
          <Box mr="12px">
            <Icons.WarningIcon width="24px" fill={colors.warning} />
          </Box>

          <Text bold fontSize="12px" color="warning" textAlign="center" lineHeight="16px">
            {t('It seems your wallet connected is active different address, please switch to account {{account}}', {
              account: formatUsername(wallet.address),
            })}
          </Text>
        </Flex>

        {walletInfo.adapter instanceof WalletConnectConnector && (
          <Button mt="12px" width="100%" onClick={onConnectByWalletType}>
            <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
              <Trans>Switch account</Trans>
            </Text>
          </Button>
        )}
      </>
    )
  }

  return (
    <Button disabled={submiting || disabled} onClick={handlePrepareWithdraw} {...props}>
      {submiting && (
        <Box mr="12px">
          <CircleLoader />
        </Box>
      )}

      <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
        <Trans>Withdraw</Trans>
      </Text>
    </Button>
  )
}

export default WalletWithdrawButton
