import Text from 'UIKit/Text'
import { ContentQuoteData } from 'components/AlchemyContent/types/quote'
import React from 'react'
import styled from 'styled-components'
import { sanitize } from 'utils/html'
import ContentBlockContainer from '../ContentBlockContainer'
import { StyledText } from '../Text'

export type ContentHeadingProps = {
  data: ContentQuoteData
}

const Quote: React.FC<ContentHeadingProps> = ({ data: { text } }) => {
  return (
    <ContentBlockContainer my="4px" p="3px 2px">
      <StyledQuoteHolder>
        <StyledText p="0px 0px 0px 14px !important" dangerouslySetInnerHTML={{ __html: sanitize(text) }} />
      </StyledQuoteHolder>
    </ContentBlockContainer>
  )
}

const StyledQuoteHolder = styled(Text)`
  border-left: 2px solid rgb(224, 224, 224);
`

export default Quote
