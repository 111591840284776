import Button from 'UIKit/Button'
import Link from 'UIKit/Link'
import Text from 'UIKit/Text'
import { ContentButtonData } from 'components/AlchemyContent/types/button'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import ContentBlockContainer from '../ContentBlockContainer'

export type ContentButtonProps = {
  data: ContentButtonData
}

const ContentButton: React.FC<ContentButtonProps> = ({ data: { alignment, name, url } }) => {
  const parsedLink = useMemo(() => {
    const urlIns = new URL(url)
    const currentHost = typeof window !== 'undefined' && window.location.host
    const isSameHost = urlIns.host === currentHost
    const pathname = urlIns.pathname
    const searchParams = new URLSearchParams(urlIns.search)

    const paramsArray = []
    for (const [key, value] of searchParams.entries()) {
      paramsArray.push(`${key}=${value}`)
    }

    const paramsString = paramsArray.length > 0 ? `?${paramsArray.join('&')}` : ''
    const combinedPath = `${pathname}${paramsString}`

    return {
      isSameHost,
      combinedPath,
    }
  }, [url])

  return (
    <StyledContainer
      display="flex"
      justifyContent={alignment === 'left' ? 'flex-start' : alignment === 'right' ? 'flex-end' : alignment}
    >
      <Button
        className="button-content"
        as={Link}
        href={parsedLink.isSameHost ? `${parsedLink.combinedPath}` : url}
        external={!parsedLink.isSameHost}
        width="100%"
      >
        <Text fontSize="14px" fontWeight={500}>
          {name}
        </Text>
      </Button>
    </StyledContainer>
  )
}

const StyledContainer = styled(ContentBlockContainer)`
  a {
    text-decoration: none !important;
  }

  .button-content {
    height: 40px;
    min-width: 120px;
  }

  margin: 4px 0px;
`

export default ContentButton
