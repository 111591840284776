import {
  EMAIL_DOMAIN_BANNED_ERROR_CODE,
  EMAIL_EXISTED_ERROR_CODE,
  EMAIL_INVALID_ERROR_CODE,
} from 'config/constants/auth'
import { ChainIdEnum } from 'config/constants/network'

import { APIEndpointEnum } from 'config/constants/server'
import {
  PrepareTraditionalVerify2FAResponse,
  PrepareWalletVerify2FAResponse,
  Verify2FAResponse,
} from 'config/types/auth'
import { delayed, getChainCode } from 'utils'
import { parseServerTime } from 'utils/dateHelper'
import { getChainTypeByWalletType } from 'utils/token'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import {
  BaseResponse,
  HunnyRequest,
  LoginResponse,
  PasswordNonceResponse,
  PrepareSignMessagePayload,
  PrepareSignMessageResponse,
  RegisterResponse,
  RegisteredEmailPayload,
  SignInByEmailPayload,
  SignInByTelegramPayload,
  SignInByWalletPayload,
  SignInSettings,
  TraditionalSignUpPreparePayload,
  Verification,
  VerificationResponse,
} from './types'

class AuthenticationService extends HunnyPlayRequest {
  public getTraditionalSignUpPrepare(
    payload: TraditionalSignUpPreparePayload,
  ): HunnyRequest<BaseResponse<PasswordNonceResponse>> {
    const result = this._post(
      APIEndpointEnum.SignUpPrepare,
      {
        username: payload.username,
        referral_code: payload.referralCode,
      },
      { excludeErrors: [EMAIL_EXISTED_ERROR_CODE, EMAIL_DOMAIN_BANNED_ERROR_CODE, EMAIL_INVALID_ERROR_CODE] },
    )
    return result
  }

  public verifyRegisterEmail(email: string): HunnyRequest<BaseResponse<VerificationResponse>> {
    return this._post(APIEndpointEnum.VerifyRegisterUser, {
      subject: email,
      type: 'email',
      username: email,
    })
  }

  public verifyResetPassword(username: string): HunnyRequest<BaseResponse<VerificationResponse>> {
    return this._post(APIEndpointEnum.VerifyResetPassword, {
      username,
    })
  }

  public async registerEmail(payload: RegisteredEmailPayload): Promise<BaseResponse<RegisterResponse>> {
    let _payload = {
      username: payload.username,
      password: payload.password,
      nonce_id: payload.nonceId,
      verifications: payload.verifications.map((verification) => ({
        type: verification.type,
        data: {
          token: verification.data.token,
          otp: verification.data.otp,
          expire_time: parseServerTime(verification.data.expireTime),
          expired_time: parseServerTime(verification.data.expiredTime),
          next_resent_time: parseServerTime(verification.data.nextResentTime),
        },
      })),
      referral_code: payload.referralCode || '',
      stag: payload.affilkaCode || '',
      device_uid: payload.deviceUid,
      password_nonce: payload?.passwordNonce,
      telegram_web_app_data: payload?.webAppData || '',
    }

    if (payload.husdBonusAmount) {
      _payload = Object.assign(_payload, { husd_bonus_amount: payload.husdBonusAmount })
    }

    const result: BaseResponse<any> = await this._request(APIEndpointEnum.Register, _payload, {
      excludeErrors: ['error_auth_input', 'error_data_expired'],
    })

    // Waiting assign new signup bonus
    await delayed(2000)

    return result
  }

  public async validateResetPassword(
    username: string,
    verification: Verification,
  ): Promise<BaseResponse<PasswordNonceResponse>> {
    const result = await this._request(
      APIEndpointEnum.ValidateResetPassword,
      {
        username,
        verification,
      },
      { excludeErrors: ['error_auth_input', 'error_data_expired'] },
    )
    return result
  }

  public async submitResetPassword(payload: {
    newPassword: string
    nonce: PasswordNonceResponse
    username: string
  }): Promise<BaseResponse<any>> {
    const result = await this._request(APIEndpointEnum.SubmitResetPassword, {
      username: payload.username,
      token: payload.nonce.token,
      nonce_id: payload.nonce.id,
      new_password: payload.newPassword,
    })
    return result
  }

  public getMessageToWalletSign(
    payload: PrepareSignMessagePayload,
  ): HunnyRequest<BaseResponse<PrepareSignMessageResponse>> {
    const parsedPayload = {
      address: payload.address,
      chain_code: getChainCode(ChainIdEnum[payload.currency.network]),
      chain_type: getChainTypeByWalletType(payload.walletType),
    }
    const result = this._post(APIEndpointEnum.WalletLoginPrepare, parsedPayload)
    return result
  }

  public getSignInByWalletSettings({
    network,
    address,
  }: {
    network: string
    address: string
  }): HunnyRequest<BaseResponse<SignInSettings>> {
    const response = this._post(APIEndpointEnum.GetSignInSettingWallet, { network, address })
    return response
  }

  public async signInByWallet(payload: SignInByWalletPayload): Promise<BaseResponse<LoginResponse>> {
    let _payload = {
      device_uid: payload.deviceUid,
      address: payload.address,
      accept_message: payload.acceptMessage,
      message_signature: payload.messageSignature,
      referral_code: payload.referralCode || '',
      stag: payload.affilkaCode || '',
      telegram_web_app_data: payload.webAppData || '',
    }

    if (payload.tonProof) {
      _payload = Object.assign(_payload, {
        ton_proof: {
          ton_network: payload.tonProof.tonNetwork,
          state_init: payload.tonProof.stateInit,
          proof: payload.tonProof.proof,
        },
      })
    }

    if (payload.totpAuthCode) {
      _payload = Object.assign(_payload, { totp_code: payload.totpAuthCode })
    }

    if (payload.husdBonusAmount) {
      _payload = Object.assign(_payload, { husd_bonus_amount: payload.husdBonusAmount })
    }

    const result = await this._request(APIEndpointEnum.SignInByWallet, _payload, { excludeErrors: ['error_auth_totp'] })

    // Waiting assign new signup bonus
    if (result?.data?.isSignUp) {
      await delayed(2000)
    }

    return result
  }

  public async getTraditionalSignInPrepare(username: string): Promise<BaseResponse<PasswordNonceResponse>> {
    const result = await this._request(APIEndpointEnum.PrepareTraditionalLogin, { username })
    return result
  }

  public async signInByEmail(payload: SignInByEmailPayload): Promise<BaseResponse<LoginResponse>> {
    const result: BaseResponse<any> = await this._request(APIEndpointEnum.SignInByEmail, {
      device_uid: payload.deviceUid,
      username: payload.username,
      password: payload.password,
      nonce_id: payload.nonceId,
      telegram_web_app_data: payload.webAppData || '',
    })

    return result
  }

  public async verifySignInByEmailBy2FA(
    payload: Partial<SignInByEmailPayload> & Partial<VerificationResponse> & Verify2FAResponse,
  ): Promise<BaseResponse<LoginResponse>> {
    const result: BaseResponse<any> = await this._request(
      APIEndpointEnum.SignInByEmailWith2FA,
      {
        device_uid: payload.deviceUid,
        token: payload.token,
        username: payload.username,
        auth_code: payload.totpAuthCode,
      },
      { excludeErrors: ['error_auth_totp'] },
    )

    return result
  }

  public async logout(): Promise<boolean> {
    const result = await this._request('api/v1/auth/logout/')
    return result?.code === 'success'
  }

  public prepareTraditionalEnable2FA(): HunnyRequest<BaseResponse<PrepareTraditionalVerify2FAResponse>> {
    const result = this._post(APIEndpointEnum.PrepareTraditionalEnable2FA, null, {
      excludeErrors: ['error_email_not_sent'],
      authRequest: true,
    })
    return result
  }

  public prepareWalletEnable2FA(
    payload: PrepareSignMessagePayload,
  ): HunnyRequest<BaseResponse<PrepareWalletVerify2FAResponse>> {
    const result = this._post(
      APIEndpointEnum.PrepareWalletEnable2FA,
      {
        chain_code: getChainCode(ChainIdEnum[payload.currency.network]),
        chain_type: getChainTypeByWalletType(payload.walletType),
      },
      { authRequest: true },
    )
    return result
  }

  public async traditionalEnable2FA({ emailAuthCode, sessionId, totpAuthcode }) {
    const result = await this._request(
      APIEndpointEnum.TraditionalEnable2FA,
      {
        email_auth_code: emailAuthCode,
        session_id: sessionId,
        totp_auth_code: totpAuthcode,
      },
      { disabledToast: true },
    )
    return result
  }

  public async prepareEnable2FAResendEmail(sessionId: string): Promise<BaseResponse<Partial<VerificationResponse>>> {
    const result = await this._request(
      APIEndpointEnum.PrepareEnable2FAResendEmail,
      { session_id: sessionId },
      { authRequest: true },
    )
    return result
  }

  public async walletEnable2FA({ sessionId, totpAuthcode, messageSignature, acceptMessage, tonProof }) {
    let _payload = {
      accept_message: acceptMessage,
      session_id: sessionId,
      totp_auth_code: totpAuthcode,
    }

    if (messageSignature) {
      _payload = Object.assign(_payload, { message_signature: messageSignature })
    }

    if (tonProof) {
      _payload = Object.assign(_payload, {
        ton_proof: {
          ton_network: tonProof.tonNetwork,
          state_init: tonProof.stateInit,
          proof: tonProof.proof,
        },
      })
    }

    const result = await this._request(APIEndpointEnum.WalletEnable2FA, _payload, {
      disabledToast: true,
      authRequest: true,
    })
    return result
  }

  public async disable2FA({ code }) {
    const result = await this._request(
      APIEndpointEnum.Disable2FA,
      { auth_code: code },
      { disabledToast: true, authRequest: true },
    )
    return result
  }

  public async signInByTelegram({
    deviceUid,
    webAppData,
    isLoginWidget = false,
    referralCode = '',
    affilkaCode = '',
  }: SignInByTelegramPayload): Promise<BaseResponse<LoginResponse>> {
    const result = await this._request(APIEndpointEnum.SignInByTelegram, {
      device_uid: deviceUid,
      web_app_data: webAppData,
      login_from: isLoginWidget ? 'web_widget' : 'web_app',
      referral_code: referralCode || '',
      stag: affilkaCode || '',
    })
    return result
  }

  public async validateLinkAccounteTelegram({
    deviceUid,
    webAppData,
    isLoginWidget,
  }: SignInByTelegramPayload): Promise<{
    isLinked: boolean
    username: string
  }> {
    const result = await this._request(APIEndpointEnum.validateLinkAccounteTelegram, {
      device_uid: deviceUid,
      web_app_data: webAppData,
      login_from: isLoginWidget ? 'web_widget' : 'web_app',
    })
    return {
      isLinked: !!result?.data?.is_link,
      username: result?.data?.username,
    }
  }

  public async getTelegramCode(): Promise<
    BaseResponse<{
      code: string
    }>
  > {
    const result = await this._request(APIEndpointEnum.TelegramStartCode, null, { authRequest: true })
    return result
  }
}

const instance = new AuthenticationService()
export default instance
