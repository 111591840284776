import { ButtonProps } from 'UIKit/Button/types'
import { SETTING_SECURITY_MODAL_KEY } from 'config/constants'
import { PrepareEnable2FA } from 'config/types/auth'
import useDebounceCallback from 'hooks/useDebounceCallback'
import useModal, { useCloseById } from 'hooks/useModal'
import { useRequest } from 'hooks/useRequest'
import { useDisconnectTon, useIsTonConnectFeatured } from 'hooks/useTonConnect'
import { PropsWithChildren } from 'react'
import AuthService from 'services/AuthenticationService'
import { useWalletInfo } from 'state/auth/hooks'
import { useUserSettings } from 'state/profile/hooks'

import { useTokenSelected } from 'state/session/hooks'
import Enable2FAModal from './Enable2FAModal'
import WalletEnable2FASignMessageModal, {
  WalletEnable2FASignMessageModalProps,
} from './WalletEnable2FASignMessageModal'
import { usePresentTonSignMessage2FA } from './hooks'
import { StyledButton } from './styled'

const WalletEnable2FAButton: React.FC<{ onDisable2FA: () => void } & ButtonProps & PropsWithChildren> = ({
  onDisable2FA,
  children,
  ...props
}) => {
  const [onPresentSignWalletModal] = useModal(WalletEnable2FASignMessageModal)
  const [onPresentEnable2FAModal] = useModal(Enable2FAModal)

  const onPresentTonSignMessage2FA = usePresentTonSignMessage2FA()
  const isTonConnected = useIsTonConnectFeatured()
  const disconnectTon = useDisconnectTon()

  const { userSettings } = useUserSettings()
  const isActive2FA = userSettings.isActive2FA

  const { wallet } = useWalletInfo()
  const tokenSelected = useTokenSelected()

  const { execute } = useRequest()
  const debounceCallback = useDebounceCallback()
  const closeModal = useCloseById()

  const handlePresentEnable2FAModal = async () => {
    const response = await execute(
      AuthService.prepareWalletEnable2FA({ walletType: wallet.type, currency: tokenSelected }),
    )

    const payload: WalletEnable2FASignMessageModalProps = {
      messageText: response.data.acceptMessageText,
      onSignSuccess: async ({ messageSignature, tonProof }) => {
        const prepareData: PrepareEnable2FA = {
          key: response.data.key,
          url: response.data.url,
          expireTime: response.data.expireTime,
          sessionId: response.data.acceptMessage?.message?.session_id,
        }

        onPresentEnable2FAModal(
          {
            prepareData,
            verifyFunction: async ({ authCode, sessionId }) => {
              const enable2FAResponse = await AuthService.walletEnable2FA({
                totpAuthcode: authCode,
                sessionId,
                acceptMessage: response.data.acceptMessage,
                messageSignature: messageSignature || '',
                tonProof,
              })

              return enable2FAResponse
            },
            onRefetchPrepareData: async () => {
              const response = await execute(
                AuthService.prepareWalletEnable2FA({ walletType: wallet.type, currency: tokenSelected }),
              )
              return response
            },
          },
          true,
        )

        closeModal(SETTING_SECURITY_MODAL_KEY)
      },
    }

    if (isTonConnected) {
      await disconnectTon()
      return onPresentTonSignMessage2FA({
        messageText: payload.messageText,
        onSignSuccess: payload.onSignSuccess,
      })
    }
    onPresentSignWalletModal({ ...payload }, true)

    return
  }

  const onHandleEnableOrClose2FA = () => {
    if (isActive2FA) {
      onDisable2FA()
      closeModal(SETTING_SECURITY_MODAL_KEY)
    } else debounceCallback(() => handlePresentEnable2FAModal(), 300)
  }

  return (
    <>
      <StyledButton
        variant={!isActive2FA ? 'primary' : 'error'}
        {...props}
        onClick={() => {
          onHandleEnableOrClose2FA()
        }}
      >
        {children}
      </StyledButton>
    </>
  )
}

export default WalletEnable2FAButton
