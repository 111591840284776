import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { BoxProps } from 'UIKit/Box/types'
import Button from 'UIKit/Button'
import Text from 'UIKit/Text'
import TransText from 'UIKit/Text/TransText'
import AlchemyDisplayContent from 'components/AlchemyDisplayContent'
import useToggle from 'hooks/useToggle'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'theme/colors'

const MINIMUM_COLLAPSED_DESCRIPTION_HEIGHT = 20

type InstructionProps = {
  instructionDetail: string
} & BoxProps

const Instruction: React.FC<InstructionProps> = ({ instructionDetail, ...props }) => {
  const [isExtendDescription, toggleExtendDescription] = useToggle(false)
  const [descriptionHeight, setDescriptionHeight] = useState<number>(0)
  const { t } = useTranslation()

  const measuredRef = useCallback((node) => {
    if (node) {
      setDescriptionHeight(node?.offsetHeight)
    }
  }, [])

  const handleToggleExtendDescription = (e) => {
    e.stopPropagation()
    e.preventDefault()
    toggleExtendDescription()
  }

  return (
    <StyledDescriptionContainer flexDirection="column" {...props}>
      <TransText fontSize="14px" fontWeight={600}>
        Status Detail
      </TransText>

      <StyledToggleExpandContainer $isExtend={isExtendDescription} marginTop="10px">
        <Box maxHeight="230px" overflowY={!isExtendDescription ? 'hidden' : 'scroll'}>
          <StyledContentCSSWrap>
            <AlchemyDisplayContent ref={measuredRef} content={instructionDetail} />
          </StyledContentCSSWrap>
        </Box>
      </StyledToggleExpandContainer>

      <>
        {!isExtendDescription && descriptionHeight > MINIMUM_COLLAPSED_DESCRIPTION_HEIGHT ? (
          <StyledToggleExtendButton onClick={handleToggleExtendDescription}>
            <Text fontSize="14px" bold style={{ textDecoration: 'underline' }} as="span">
              ...{t('See more')}
            </Text>
          </StyledToggleExtendButton>
        ) : null}
        {isExtendDescription ? (
          <StyledToggleExtendButton onClick={handleToggleExtendDescription}>
            <Text fontSize="14px" bold style={{ textDecoration: 'underline' }}>
              {t('Collapse')}
            </Text>
          </StyledToggleExtendButton>
        ) : null}
      </>
    </StyledDescriptionContainer>
  )
}

const StyledDescriptionContainer = styled(Flex)`
  margin-top: 20px;
  position: relative;
`

const StyledToggleExtendButton = styled(Button).attrs({ variant: 'text' })`
  padding: 6px;
  height: fit-content;
  background: ${colors.background};
  position: absolute;
  right: 0px;
  bottom: 10px;
`

const StyledToggleExpandContainer = styled(Box)<{ $isExtend: boolean }>`
  max-height: ${({ $isExtend }) => ($isExtend ? '900px' : '32px')};
  margin-bottom: ${({ $isExtend }) => ($isExtend ? '25px' : '0px')};

  will-change: transition;
  transition: ${({ theme: { transitions } }) => transitions.medium};
  overflow: hidden;
`

const StyledContentCSSWrap = styled(Box)`
  & > * {
    color: ${({ theme }) => theme.colors.textTertiary};
    font-size: 14px;
  }
  & a {
    color: ${({ theme }) => theme.colors.text};
  }
`

export default Instruction
