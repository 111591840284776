/* eslint-disable no-template-curly-in-string */
import {
  BoosterWagerCommonBonus,
  CashCommonBonus,
  DepositCashCommonBonus,
  DepositCommonBonus,
  DepositFreeSpinCommonBonus,
  FreeHUSDLockCommonBonus,
  FreeLuckyspinCommonBonus,
  FreeSpinCommonBonus,
  HUSDUnLockBoosterCommonBonus,
  LevelUpCommonBonus,
  NoWageringCommonCashBonus,
} from 'config/types/bonus'
import {
  BoosterWagerBonus,
  CashBonus,
  DepositBonus,
  DepositCashBonus,
  DepositFreespinBonus,
  FreeHUSDLockBonus,
  FreeLuckyspinBonus,
  FreespinBonus,
  HUSDUnLockBoosterBonus,
  LevelUpBonus,
  NoWageringCashBonus,
} from 'config/types/bonus/userBonus'
import { buildPluralizeText } from 'utils'

export const buildTournamentBonusNameAmount = (bonus: any): [string, string] => {
  if (!bonus) return ['', '']

  if (bonus instanceof LevelUpBonus || bonus instanceof LevelUpCommonBonus) {
    return [
      buildPluralizeText('VIP {{level}}', [{ number: bonus.extraLevels, key: 'level', word: ['level', 'levels'] }]),
      `+${bonus.extraLevels || 0}`,
    ]
  }

  if (bonus instanceof HUSDUnLockBoosterBonus || bonus instanceof HUSDUnLockBoosterCommonBonus) {
    return ['wager booster', `${bonus.extraUnlockPercent || 0}%`]
  }

  if (bonus instanceof FreeLuckyspinBonus || bonus instanceof FreeLuckyspinCommonBonus) {
    return [
      buildPluralizeText('lucky {{spin}}', [{ number: bonus.amount, key: 'spin', word: ['spin', 'spins'] }]),
      `${bonus?.amount || 0}`,
    ]
  }

  if (bonus instanceof FreeHUSDLockBonus || bonus instanceof FreeHUSDLockCommonBonus) {
    return ['locked HUSD', `${bonus.amount || 0}`]
  }

  if (bonus instanceof DepositBonus || bonus instanceof DepositCommonBonus) {
    return ['on deposit', `${(bonus?.percent || 0).toString()}%`]
  }

  if (
    bonus instanceof FreespinBonus ||
    bonus instanceof FreeSpinCommonBonus ||
    bonus instanceof DepositFreespinBonus ||
    bonus instanceof DepositFreeSpinCommonBonus
  ) {
    return [
      buildPluralizeText('free {{spin}}', [{ number: bonus.freeSpinAmount, key: 'spin', word: ['spin', 'spins'] }]),
      `${bonus?.freeSpinAmount || 0}`,
    ]
  }

  if (
    bonus instanceof CashBonus ||
    bonus instanceof CashCommonBonus ||
    bonus instanceof NoWageringCashBonus ||
    bonus instanceof NoWageringCommonCashBonus
  ) {
    return [bonus.bonusAmount?.token?.code, `${(bonus.bonusAmount?.amount || 0).toString()}`]
  }

  if (bonus instanceof DepositCashBonus || bonus instanceof DepositCashCommonBonus) {
    return [bonus.bonusAmount?.token?.code, `${(bonus.bonusAmount?.amount || 0).toString()}`]
  }

  if (bonus instanceof BoosterWagerBonus || bonus instanceof BoosterWagerCommonBonus) {
    return ['wager booster', `${(bonus.boostPercent || 0).toString()}%`]
  }

  return ['', '']
}
