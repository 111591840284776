import { BIG_ZERO } from 'config/constants/number'
import { BonusStatusEnums } from 'config/types/bonus'
import {
  ApiBonusStatusEnums,
  DepositBonus,
  DepositCashBonus,
  DepositFreespinBonus,
  WageringConditionBonus,
} from 'config/types/bonus/userBonus'
import useDebounceCallback from 'hooks/useDebounceCallback'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import BonusService from 'services/BonusService'
import { AppState } from 'state'
import { useActivatedBonuses, useUserBonus } from 'state/bonus/hooks'

export const emptyBonus = { id: 0 } as any

export const useWaitingDepositToActiveBonus = () => {
  const [bonuses, setBonuses] = useState<WageringConditionBonus[]>([])
  const [selectedBonus, setSelectedBonus] = useState<WageringConditionBonus>(emptyBonus)
  const [isSelectedBonusExpired, setIsSelectedBonusExpired] = useState(false)

  const { activatedBonuses } = useActivatedBonuses()
  const debounceCallback = useDebounceCallback()
  const currentSelectedBonusRef = useRef(emptyBonus)

  useEffect(() => {
    const fetch = async () => {
      const response = await BonusService.getWaitingDepositUserBonuses().call()
      const bonuses = response?.data?.items || []

      if (bonuses.length > 0)
        setBonuses([
          emptyBonus,
          ...(bonuses.filter((bonus) => bonus instanceof WageringConditionBonus) as WageringConditionBonus[]),
        ])
      else setBonuses([])

      const selectedBonus = bonuses.find((bonus) => bonus.id === response.data?.selectedDepositBonusId)
      currentSelectedBonusRef.current = selectedBonus || emptyBonus
      setSelectedBonus(selectedBonus || emptyBonus)

      if (selectedBonus?.id > 0) {
        await BonusService.prepareDepositBonus(selectedBonus.id)
      }
    }
    fetch()
  }, [activatedBonuses])

  useEffect(() => {
    if (selectedBonus.id === 0) {
      setIsSelectedBonusExpired(false)
      return
    }

    setIsSelectedBonusExpired(selectedBonus.condition.isExpired)
    const interval = setInterval(() => setIsSelectedBonusExpired(selectedBonus.condition.isExpired), 1000)

    return () => clearInterval(interval)
  }, [selectedBonus])

  const handleSelectedBonus = async (bonus: WageringConditionBonus) => {
    setSelectedBonus(bonus)

    debounceCallback(async () => {
      const isSuccess = await BonusService.prepareDepositBonus(bonus.id)
      if (isSuccess) {
        currentSelectedBonusRef.current = bonus
      } else {
        setSelectedBonus(currentSelectedBonusRef.current)
      }
    }, 500)
  }

  return { bonuses, handleSelectedBonus, selectedBonus, isSelectedBonusExpired }
}

export const useSummarizedInfoBonus = () => {
  const { bonuses } = useUserBonus()
  const tokenUsdPrices = useSelector((state: AppState) => state.app.tokenUsdPrices)

  return useMemo(() => {
    try {
      if (bonuses.length > 0) {
        const data = bonuses
          .reduce(
            (state, bonus) => {
              if (bonus.status !== BonusStatusEnums.Available) return state

              if (bonus instanceof DepositBonus && bonus?.apiStatus === ApiBonusStatusEnums.WaitDeposit) {
                state[0].value = state[0].value.plus(bonus.percent)
              }
              if (bonus instanceof DepositFreespinBonus && bonus?.apiStatus === ApiBonusStatusEnums.WaitDeposit) {
                state[1].value = state[1].value.plus(bonus.freeSpinAmount)
              }
              if (bonus instanceof DepositCashBonus && bonus?.apiStatus === ApiBonusStatusEnums.WaitDeposit) {
                state[2].value = state[2].value.plus(
                  bonus.bonusAmount.amount.multipliedBy(tokenUsdPrices?.[bonus.bonusAmount.token.code] || 0), // convert to $
                )
              }

              return [...state]
            },
            [
              {
                value: BIG_ZERO, // depositBonus
                unit: '+{{value}}%',
              },
              {
                value: BIG_ZERO, // depositFreeSpinBonuse
                unit: '+{{value}} FS',
              },
              {
                value: BIG_ZERO, // depositWagerBonus
                // eslint-disable-next-line no-template-curly-in-string
                unit: '+${{value}}',
              },
            ],
          )
          .filter((item) => item.value.gt(0))

        if (data?.length > 0 && data?.[0]?.value?.gt(0)) {
          return data[0].unit.replace('{{value}}', (data?.[0].value || 0).toFixed(0))
        }
      }
      return ''
    } catch {
      return ''
    }
  }, [bonuses, tokenUsdPrices])
}
