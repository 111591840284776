import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Button from 'UIKit/Button'
import Image from 'UIKit/Image'
import CircleLoader from 'UIKit/Loader/CircleLoader'
import Modal from 'UIKit/Modal'
import ModalBody from 'UIKit/Modal/components/ModalBody'
import { ModalProps } from 'UIKit/ModalV2'
import Text from 'UIKit/Text'
import { useTonConnectStatus } from 'hooks/useTonConnect'
import { RowCenter, RowMiddle } from 'layout/Components/Row'
import React from 'react'
import { Trans } from 'react-i18next'
import { PrepareSignMessageResponse } from 'services/types'
import { useAuth, useWalletInfo } from 'state/auth/hooks'
import styled from 'styled-components'
import { formatUsername } from 'utils/stringHelper'

interface ConnectProps {
  onWalletSigned?: (messageSignature?: PrepareSignMessageResponse) => void
  isSigning?: boolean
}

const ConnectWalletSection: React.FC<ConnectProps> = ({ isSigning }) => {
  const { isUserDisconnectWallet, isUserConnectInvalidAddress } = useTonConnectStatus()
  const { username } = useAuth()
  const { onConnectByWalletType } = useWalletInfo()

  return (
    (isUserDisconnectWallet || isUserConnectInvalidAddress) && (
      <>
        <Image alt="join" width={80} height={80} src="/images/join.png" />
        <Text bold fontSize="18px" lineHeight="24px" mt="16px">
          <Trans>Connect Your Wallet</Trans>
        </Text>
        {isUserDisconnectWallet && (
          <Text color="textSubtle" fontSize="14px" lineHeight={1.2} mt="28px" textAlign="center">
            <Trans
              i18nKey="tonConnectLinkAccountInstruction"
              values={{ account: formatUsername(username) }}
              components={{ highlight_tag: <Text as="span" fontWeight={700} fontSize="14px" lineHeight={1.2} /> }}
            />
          </Text>
        )}
        {isUserConnectInvalidAddress && (
          <Text color="textSubtle" fontSize="14px" lineHeight={1.2} mt="28px" textAlign="center">
            <Trans
              i18nKey="tonConnectInvalidAccountConnect"
              values={{ account: formatUsername(username) }}
              components={{ highlight_tag: <Text as="span" fontWeight={700} fontSize="14px" lineHeight={1.2} /> }}
            />
          </Text>
        )}
        <StyledButton
          id="signmessage-button"
          width="100%"
          mt="40px"
          onClick={() => onConnectByWalletType({ ignoreSignInSystem: true })}
          disabled={isSigning}
        >
          <RowMiddle>
            <Trans>Connect Wallet</Trans>
          </RowMiddle>
        </StyledButton>
      </>
    )
  )
}

const SigningWalletSection: React.FC<ConnectProps> = ({ onWalletSigned, isSigning }) => {
  const { isUserConnectAndSignIn } = useTonConnectStatus()
  const { username } = useAuth()

  return (
    isUserConnectAndSignIn && (
      <>
        <RowCenter alignSelf={'flex-start'} mb="12px">
          ✅
          <Text color="success" fontSize="12px" lineHeight={1.2} fontWeight={500}>
            <Trans>Wallet Connected Successfully</Trans>!
          </Text>
        </RowCenter>
        <Image alt="join" width={80} height={80} src="/images/join.png" />
        <Text bold fontSize="18px" lineHeight="24px" mt="16px">
          <Trans>Signing Your Request</Trans>
        </Text>
        <Text color="textSubtle" fontSize="14px" lineHeight={1.2} mt="28px" textAlign="center">
          <Trans
            i18nKey="tonConnectSingMessageInstruction"
            values={{ account: formatUsername(username) }}
            components={{ highlight_tag: <Text as="span" fontWeight={700} fontSize="14px" lineHeight={1.2} /> }}
          />
        </Text>

        <StyledButton id="signmessage-button" width="100%" mt="40px" onClick={onWalletSigned} disabled={isSigning}>
          {isSigning && isUserConnectAndSignIn ? (
            <RowMiddle>
              <Box mr="8px">
                <CircleLoader />
              </Box>
              <Trans>Waiting for Signature</Trans>
            </RowMiddle>
          ) : (
            <Trans>Sign Message</Trans>
          )}
        </StyledButton>
      </>
    )
  )
}

const TonConnectSignWallet: React.FC<ModalProps<ConnectProps>> = ({ data, onDismiss }) => {
  return (
    <StyledModal id="popup-signwallet" onDismiss={onDismiss} p="0 !important">
      <ModalBody>
        <Box p="40px 34px">
          <Flex alignItems="center" flexDirection="column">
            <ConnectWalletSection isSigning={data.isSigning} />
            <SigningWalletSection {...data} />
          </Flex>
        </Box>
      </ModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 440px !important;
    max-width: 440px !important;
  }
`

const StyledButton = styled(Button)`
  font-size: 12px;
  font-weight: bold;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.primaryDisabled};
  }
`

export default TonConnectSignWallet
