import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import { BoxProps } from 'UIKit/Box/types'
import Popper from 'UIKit/Popper'
import { PopperProps } from 'UIKit/Popper/typpes'
import useDebounceCallback from 'hooks/useDebounceCallback'
import useKeydownEventListener from 'hooks/useKeydownEventListener'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { AppSearch } from 'layout/Components/GameSearch/styled'
import { useCallback, useMemo, useRef, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'

export type SelectDropdownProps = {
  toggle: boolean
  setOpen: (open: boolean) => void
  onDismiss?: () => void
  OptionItemComponent: React.FC<{ item: any }>
  onSelect: (value: any) => void
  options: any[]
  onFilterSearch?: (item: any[], searchString: string) => any[]
}

export const SelectDropdown: React.FC<SelectDropdownProps & BoxProps> = ({
  toggle,
  setOpen,
  OptionItemComponent,
  options,
  onDismiss,
  onSelect,
  maxHeight,
  onFilterSearch,
  ...props
}) => {
  const { isMobile } = useMatchBreakpoints()
  const containerRef = useRef<HTMLDivElement>(null)
  const [searchString, setSearchString] = useState<string>('')
  const debounce = useDebounceCallback()

  const eventHandler = useCallback(
    (event) => {
      if (event && event.key === 'ArrowDown') {
        event.preventDefault()

        const next = document.activeElement.nextSibling as HTMLElement
        if (next) {
          next.focus()
        } else {
          const firstChild = containerRef.current.firstChild as HTMLElement
          firstChild.focus()
        }
      } else if (event && event.key === 'ArrowUp') {
        event.preventDefault()

        const previous = document.activeElement.previousSibling as HTMLElement
        if (previous) {
          previous.focus()
        } else {
          const lastChild = containerRef.current.lastChild as HTMLElement
          lastChild.focus()
        }
      } else if (event && event.key === 'Enter') {
        const activeElement = document.activeElement as HTMLElement
        if (activeElement) {
          activeElement.click()
        }
      } else if (event && event.key === 'Escape') {
        onDismiss()
      }
    },
    [options],
  )

  useKeydownEventListener(eventHandler)

  const styleOpenOption = useSpring({
    config: { duration: 150 },
    opacity: toggle ? 1 : 0,
    y: toggle ? 0 : 20,
    from: toggle ? { opacity: 0, y: 20 } : { opacity: 1, y: 0 },
    onResolve: () => {
      if (!toggle) {
        setOpen(false)
      }
    },
  })

  const formatOptions = useMemo(() => {
    if (typeof onFilterSearch === 'function') {
      return onFilterSearch(options, searchString)
    }

    return options
  }, [options, onFilterSearch, searchString])

  return (
    <StyledAnim style={{ ...styleOpenOption }}>
      <StyledOptionsContainer background={colors.inputAlt} {...props}>
        {onFilterSearch && (
          <AppSearch
            mx="8px"
            my="8px"
            autoFocus={!isMobile}
            placeholder={'Search'}
            onValueChanged={(value) => {
              debounce(() => setSearchString(value), 300)
            }}
          />
        )}

        <StyledOptionsInnerContainer ref={containerRef} maxHeight={maxHeight}>
          {formatOptions.map((item, index) => (
            <StyledOptionItem
              className="option-items"
              autoFocus={!onFilterSearch && index === 0}
              key={index}
              $disabled={item?.disabled || false}
              onClick={() => {
                if (!item?.disabled) onSelect(item)
                onDismiss()
              }}
            >
              <OptionItemComponent item={item} />
            </StyledOptionItem>
          ))}
        </StyledOptionsInnerContainer>
      </StyledOptionsContainer>
    </StyledAnim>
  )
}

export interface SelectProps extends PopperProps {
  options: any[]
  OptionItemComponent: React.FC<{ item: any }>
  onSelect: (value: any) => void
  dropdownContentProps?: BoxProps
  onSelectOpen?: (isOpen: boolean) => void
  onFilterSearch?: (options: any[], searchString: string) => any[]
}

export const Select: React.FC<SelectProps> = ({
  OptionItemComponent,
  options,
  onSelect,
  children,
  dropdownContentProps,
  onSelectOpen,
  disabled,
  onFilterSearch,
  ...props
}) => {
  const [toggle, setToggle] = useState(false)
  const [open, setOpen] = useState(false)

  const handleClickSelect = useCallback(
    (value: boolean) => {
      if (value && options.length > 0) {
        setOpen(true)
        setToggle(true)
      } else {
        setToggle(false)
      }

      if (onSelectOpen) {
        onSelectOpen(!!(value && options.length > 0))
      }
    },
    [open, onSelectOpen, options],
  )

  return (
    <Popper
      open={open}
      setOpen={handleClickSelect}
      disabled={disabled}
      dropdownContent={
        <SelectDropdown
          toggle={toggle}
          setOpen={setOpen}
          OptionItemComponent={OptionItemComponent}
          options={options}
          onSelect={onSelect}
          onFilterSearch={onFilterSearch}
          {...dropdownContentProps}
        />
      }
      dropdownPosition="bottom"
      {...props}
    >
      <Flex height="100%" alignItems="center">
        <Flex flex="1 1" width="100%">
          {children}
        </Flex>
        {options?.length > 0 && !disabled && (
          <StyledExpansitionIconContainer $Open={open}>
            <Icons.ArrowDownIcon fill={colors.textSubtle} />
          </StyledExpansitionIconContainer>
        )}
      </Flex>
    </Popper>
  )
}

export const StyledExpansitionIconContainer = styled.div<{ $Open: boolean }>`
  transition: ${({ theme }) => theme.transitions.fast};
  transform: ${({ $Open }) => ($Open ? 'rotate(180deg) translateY(4px)' : '')};
  margin-top: 4px;
  margin-right: 4px;
  padding: 4px;
`

const StyledAnim = styled(animated.div)`
  width: 100%;
  box-sizing: border-box;
`

export const StyledOptionsContainer = styled(animated.div)`
  margin-top: 6px;
  border-radius: ${({ theme }) => theme.radii.small};
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  background: ${({ theme }) => theme.colors.inputAlt};
  overflow: hidden;

  ${AppSearch} {
    overflow: unset;
    input {
      height: 44px;
      border-radius: 0;
    }
  }
`

const StyledOptionsInnerContainer = styled(Box)`
  width: 100%;
  scroll-behavior: smooth;
  margin: 8px 0px;
  scrollbar-width: none;
  max-height: 300px;
  overflow-y: overlay;
  overflow-x: hidden;

  ::-webkit-scrollbar-thumb {
    visibility: visible;
  }
`

const StyledOptionItem = styled.button<{ $disabled?: boolean }>`
  cursor: pointer;
  background: ${({ $disabled }) => ($disabled ? 'rgba(255, 255, 255, 0.05)' : 'transparent')};
  display: block;
  width: 100%;
  border-radius: 0px;
  padding: 12px;
  outline: none;
  border: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }

  &:focus-visible {
    background-color: rgba(0, 0, 0, 0.15);
  }
  &:disabled {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }
`

export default Select
