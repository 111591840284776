import { InitData, useInitDataRaw } from '@telegram-apps/sdk-react'
import { AuthMethod } from 'config/constants/auth'
import { TelegramLoginPayload, TelegramLoginWebAppPayload, TelegramLoginWebWidgetPayload } from 'config/types/telegram'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AuthenticationService from 'services/AuthenticationService'
import ProfileService from 'services/ProfileService'
import { SignInByTelegramPayload } from 'services/types'
import { useAppDispatch, useAppSelector } from 'state'
import { updateTelegramId } from 'state/auth/action'
import { useAuth, useAuthBy, useClientLogin, useLogout } from 'state/auth/hooks'
import { updateUserLinkTelegram } from 'state/profile/actions'
import { useUserOnboardingStatus } from 'state/profile/hooks'
import { getID } from 'utils/fingerprint'
import { detectIsTelegramOrigin, getInitData, getPlatformTelegram } from 'utils/telegram'
import { HunnyToast } from 'utils/toastify'
import { getWalletTypeByAddress } from 'utils/token'
import { getTelegramUrlStartBot, serializeTelegram } from 'utils/urlHelper'
import WarningLoginUnLinkModal from 'views/Authentication/components/Wallet/WarningLoginUnLinkModal'
import ConfirmUnLinkTelegramModal from 'views/Profile/Dashboard/components/Telegram/ConfirmUnLinkTelegramModal'
import useModal from './useModal'
import useNewUserJourney from './useNewUserJourney'
import { usePrivateSocket } from './usePrivateSocket'
import useQueryParam from './useQueryParam'
import { useReferralCode } from './useReferralCode'
import { useRouter } from './useRouter'

export const useInitDataTele = (): InitData | undefined => {
  const initData = useInitDataRaw()
  return useMemo(() => {
    return initData?.result
  }, [initData])
}

export const useIsTelegramOrigin = () => {
  const data = useInitDataTele()

  return useMemo(() => {
    return detectIsTelegramOrigin()
  }, [data])
}

export const usePlatformTelegram = () => {
  const initData = useInitDataRaw()

  return useMemo(() => {
    const platform = getPlatformTelegram()

    return {
      isWebApp: platform === 'weba',
      isDesktopApp: platform === 'tdesktop',
    }
  }, [initData])
}

export const useIsDisabledFeaturedOnTelegram = () => {
  const { isAuthByWallet, isAuthByTelegram } = useAuthBy()
  const isTelegramOrigin = useIsTelegramOrigin()

  // this is use for disabled features deposit/withdraw/2FA for account web3
  return useMemo(() => {
    return isTelegramOrigin && isAuthByWallet
  }, [isAuthByWallet, isTelegramOrigin, isAuthByTelegram])
}

export const useIsDisabledConnectWeb3 = () => {
  const { isWebApp } = usePlatformTelegram()
  const isTelegramOrigin = useIsTelegramOrigin()

  return useMemo(() => {
    return isTelegramOrigin
  }, [isWebApp, isTelegramOrigin])
}

export const useBuildTeleAppData = (): {
  webAppData: string
  payload: TelegramLoginWebAppPayload
} => {
  const initData = useInitDataTele()
  const isTelegramOrigin = useIsTelegramOrigin()

  return useMemo(() => {
    if (!isTelegramOrigin || !initData || !initData?.user || !initData?.user?.id) {
      return {
        payload: {
          user: {
            id: 0,
            first_name: '',
            last_name: '',
            username: '',
            language_code: '',
            allows_write_to_pm: false,
          },
          query_id: '',
          auth_date: 0,
          hash: '',
        },
        webAppData: '',
      }
    }

    const user = {
      id: initData.user.id,
      first_name: initData.user.firstName,
      last_name: initData.user.lastName,
      username: initData.user.username,
      language_code: initData.user.languageCode,
      allows_write_to_pm: initData.user.allowsWriteToPm,
    }

    const payload = {
      user,
      query_id: initData.queryId || '',
      auth_date: initData.authDate.getTime() / 1000,
      hash: initData.hash,
    }

    return {
      payload,
      webAppData: getInitData() || serializeTelegram(payload),
    }
  }, [isTelegramOrigin, initData])
}

const getPayloadTelegram = (payload: TelegramLoginPayload) => {
  const isLoginWidget = Boolean((payload as TelegramLoginWebWidgetPayload)?.id)

  const username = isLoginWidget
    ? (payload as TelegramLoginWebWidgetPayload)?.username
    : (payload as TelegramLoginWebAppPayload)?.user?.username
  const id = isLoginWidget
    ? (payload as TelegramLoginWebWidgetPayload)?.id
    : (payload as TelegramLoginWebAppPayload)?.user?.id

  return {
    isLoginWidget,
    username,
    id,
  }
}

export const useLoginTelegram = () => {
  const login = useClientLogin()
  const refCode = useReferralCode()
  const startJourney = useNewUserJourney()
  const { update } = useUserOnboardingStatus()
  const router = useRouter()
  const { removeParam } = useQueryParam()

  return useCallback(
    async (webAppData: string, payload: TelegramLoginPayload) => {
      if (!payload) return

      const { isLoginWidget, id } = getPayloadTelegram(payload)

      if (!id) return

      const deviceUid = await getID()

      const params: SignInByTelegramPayload = {
        webAppData: webAppData || serializeTelegram(payload),
        deviceUid,
        isLoginWidget,
        referralCode: '',
        affilkaCode: '',
      }

      if (refCode.type === 'affilka') {
        params.affilkaCode = refCode.refCode
      } else if (refCode.type === 'hunnyplay') {
        params.referralCode = refCode.refCode
      }

      const result = await AuthenticationService.signInByTelegram(params)

      if (result?.data?.accessToken) {
        const username = result.data.username || id.toString()

        const isAuthByWallet = result.data.authBy === AuthMethod.Wallet

        login(
          {
            authBy: result.data.authBy,
            uid: result.data.uid,
            username,
            email: isAuthByWallet ? null : username,
            wallet: isAuthByWallet
              ? {
                  name: null,
                  type: getWalletTypeByAddress(username),
                  connectorId: null,
                  address: username,
                  trackingName: null,
                }
              : null,
          },
          deviceUid,
        )

        if (result.data?.isSignUp) {
          update(true)
          const bonus = (router?.query?.bonus || '')?.toString() || ''

          startJourney({
            husdBonusAmount: result?.data?.husdBonusAmount,
            bonusCode: bonus,
          })
          removeParam('bonus')
        }
      }
    },
    [login, refCode, removeParam, startJourney, update, router],
  )
}

export const useDisplayUsernameTelegram = () => {
  const telegram = useAppSelector((state) => state.profile.telegram)

  return useMemo(() => {
    const username = telegram?.username

    return {
      username,
      displayUsername: !username?.length ? '*****' : username,
      connected: telegram?.connected,
      id: telegram?.id,
    }
  }, [telegram])
}

export const useAutoLoginTelegram = () => {
  const { webAppData, payload } = useBuildTeleAppData()
  const { hasSession } = useAuth()

  const login = useLoginTelegram()
  const logout = useLogout()

  const previousTelegramId = useAppSelector((state) => state.auth.telegramId)
  const previousUsername = useAppSelector((state) => state.auth.username)

  return useCallback(async () => {
    if (!webAppData) return false

    const deviceUid = await getID()
    const { isLinked, username } = await AuthenticationService.validateLinkAccounteTelegram({
      webAppData,
      deviceUid,
    })

    const isLoggedIn = hasSession
    const isMatchId = previousTelegramId > 0 && previousTelegramId === payload.user.id
    const isMatchUsername = previousUsername && previousUsername === username

    const isMatchIdButUnlink = Boolean(isLoggedIn && !isLinked && isMatchId && previousUsername)
    const isMatchIdButDifferentUsername = Boolean(isLoggedIn && isLinked && isMatchId && !isMatchUsername)

    if (isLinked && !isLoggedIn) {
      login(webAppData, payload)
    } else if (isMatchIdButUnlink || isMatchIdButDifferentUsername) {
      logout()
    }

    return true
  }, [webAppData, payload, hasSession, previousTelegramId, previousUsername])
}

export const useLoginByCheckLinkedTelegram = () => {
  const login = useLoginTelegram()
  const [onPresent] = useModal(WarningLoginUnLinkModal)

  return useCallback(
    async (webAppData: string, payload: TelegramLoginPayload, callbackChangePage: () => void) => {
      const { id, isLoginWidget } = getPayloadTelegram(payload)

      if (!id) return

      const deviceUid = await getID()

      const { isLinked } = await AuthenticationService.validateLinkAccounteTelegram({
        webAppData: webAppData || serializeTelegram(payload),
        deviceUid,
        isLoginWidget,
      })

      if (isLinked) {
        login(webAppData, payload)
      } else {
        onPresent({
          callbackSignUp: callbackChangePage,
        })
      }
    },
    [login],
  )
}

const errorsLinkMessage = {
  error_user_linked_another_account: 'This Telegram account has already been used.',
}

export const useLinkAccountTelegram = () => {
  const [loading, setLoading] = useState(false)
  const [onPresent, onDimiss] = useModal(ConfirmUnLinkTelegramModal)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const logout = useLogout()
  const isTelegramOrigin = useIsTelegramOrigin()
  const { payload, webAppData } = useBuildTeleAppData()

  const handleUnLinkAccount = useCallback(async () => {
    if (loading) return

    setLoading(true)
    const result = await ProfileService.unLinkTelegram()

    if (result.code === 'success') {
      dispatch(
        updateUserLinkTelegram({
          username: '',
          connected: false,
          id: 0,
        }),
      )
      dispatch(updateTelegramId(0))
      if (isTelegramOrigin) {
        logout()
      } else {
        HunnyToast.success(t(''), t('Successfully Disconnect Telegram'))
      }
    } else {
      HunnyToast.error(
        t(''),
        t(errorsLinkMessage?.[result.code] || errorsLinkMessage?.[result.errors?.[0]] || result.message),
      )
    }

    setLoading(false)
    return true
  }, [dispatch, t, loading, onDimiss])

  const handlePresentUnLinkModal = () => {
    onPresent({
      onDisconnect: handleUnLinkAccount,
    })
  }

  const handleLinkAccountByWebAppData = useCallback(
    async (webAppData: string, payload: TelegramLoginPayload) => {
      if (!payload) return

      const { username, id, isLoginWidget } = getPayloadTelegram(payload)

      if (!id || loading) return

      setLoading(true)

      const result = await ProfileService.linkTelegram({
        isLoginWidget,
        webAppData: webAppData || serializeTelegram(payload),
      })
      if (result.code === 'success') {
        dispatch(
          updateUserLinkTelegram({
            id,
            username,
            connected: true,
          }),
        )

        dispatch(updateTelegramId(id))

        HunnyToast.success(t(''), t('Telegram connection is complete'))
      } else {
        HunnyToast.error(
          t(''),
          t(errorsLinkMessage?.[result.code] || errorsLinkMessage?.[result.errors?.[0]] || result.message),
        )
      }
      setLoading(false)
    },
    [t, loading],
  )

  const handleLinkAccountByUrlBot = async () => {
    if (loading) return

    setLoading(true)

    const result = await AuthenticationService.getTelegramCode()
    if (result.code === 'success') {
      window.open(getTelegramUrlStartBot(result.data.code), '_blank')
    } else {
      HunnyToast.error(result.message)
    }

    setTimeout(() => setLoading(false), 3000)
  }

  const handleLinkAccount = () => {
    if (isTelegramOrigin) {
      if (webAppData) {
        handleLinkAccountByWebAppData(webAppData, payload)
      }
    } else {
      handleLinkAccountByUrlBot()
    }
  }

  return {
    loading,
    linkAccount: handleLinkAccount,
    unlinkAccount: handlePresentUnLinkModal,
  }
}

export const useListenerLinkedTelegram = () => {
  const socket = usePrivateSocket()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { isSigned } = useAuth()

  const handleLogin = async (data) => {
    dispatch(
      updateUserLinkTelegram({
        id: data.id,
        username: data.username || '',
        connected: true,
      }),
    )
    dispatch(updateTelegramId(data.id))
    HunnyToast.success(t(''), t('Telegram connection is complete'))
  }

  useEffect(() => {
    if (!socket) {
      return
    }

    socket.on('auth.telegram.connected', (data) => {
      if (data.id) {
        handleLogin(data)
      }
    })

    return () => {
      if (socket) {
        socket.off('auth.telegram.connected')
      }
    }
  }, [isSigned, socket])
}
