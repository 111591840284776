import { FIAT_CURRENCIES, USD_FIAT_CURRENCY } from 'config/constants/fiat'
import { PrepareEnable2FA, PrepareWalletVerify2FAResponse } from 'config/types/auth'
import { RefUserProfile } from 'config/types/profile'
import {
  BaseResponse,
  LoginResponse,
  PasswordNonceResponse,
  PrepareSignMessageResponse,
  RegisterResponse,
  SignInSettings,
  UserDataResponse,
  VerificationResponse,
} from 'services/types'
import { AuthMethod } from 'config/constants/auth'
import { parseBalances } from './Payment'
import { parseUserBonus } from './Voucher'

const mapperAuthType = (type: string) => {
  switch (type) {
    // ADD WALLET
    case 'blockchain.evm':
      return AuthMethod.Wallet
    case 'blockchain.solana':
      return AuthMethod.Wallet
    case 'blockchain.ton':
      return AuthMethod.Wallet
    case 'email':
      return AuthMethod.Email
    case 'telegram':
      return AuthMethod.Telegram
    default:
      return AuthMethod.Email
  }
}

export const UserDataMapper = (rawResponse: string): BaseResponse<UserDataResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  const data = response?.data
  if (!response || response?.code !== 'success') {
    return {
      code: response?.code || 'error',
      data: null,
    }
  }

  const balances = data?.balances.map((balance) => parseBalances(balance))
  const userBonuses = data?.activate_vouchers.map((voucher) => parseUserBonus(voucher)).filter((item) => item)

  const tier = data?.tier_id
  const totalWager = data?.total_wager || 0
  const totalBet = data?.bet_count || 0

  const sevenDays = 60 * 60 * 24 * 7 * 1000
  const isClaimed = data?.is_received_welcome_package
  const endTime = data?.register_time ? data.register_time * 1000 + sevenDays : 0
  const endTimeAt = isClaimed ? 0 : endTime
  const fiatCode = data.settings.fiat_currency

  const selectedFiatCurrency = FIAT_CURRENCIES.find((item) => {
    return item.code === fiatCode
  })

  return {
    code: response.code,
    message: response.message,
    data: {
      balances,
      profile: {
        tier,
        totalWager,
        totalBet,
        displayName: data.display_name || '',
        avatar: data.avatar_url || '',
        canUpdateDisplayNameAt: data.next_change_display_name || 0,
        userCode: data?.user_code || '',
        registeredDate: new Date((data?.register_time || 0) * 1000),
        email: data?.email || null,

        telegram: {
          connected: data.telegram?.is_connect || false,
          username: data.telegram?.username || '',
          id: data.telegram?.id || 0,
        },
      },
      welcomePackage: {
        isClaimed,
        expiredAt: endTimeAt ? (endTimeAt > Date.now() ? endTimeAt : 0) : 0,
      },
      userBonuses,
      notificationUnreadCount: {
        personal: data.noti_unread_count?.personal || 0,
        system: data.noti_unread_count?.platform || 0,
      },
      bonusBalances: {
        HUSD: data.bonus_balances?.find((item) => item.currency === 'HUSD')?.amount || '0',
      },
      settings: {
        disableAirdropAnimation: data.settings.hide_air_drop === 1,
        disableZeroBalance: data.settings.hide_zero_balance === 1,
        disablePublicDisplayName: data.settings.hide_display_name === 1,
        disableStatistic: data.settings.hide_statistic === 1,
        disablePlayedGameSection: data.settings.hide_played_games === 1,
        disableDisplayInFiat: data.settings.hide_fiat === 1,
        selectedFiatCurrency: selectedFiatCurrency || USD_FIAT_CURRENCY,
        isEnable2FAForLogin: data.settings.totp_login_required === 1,
        isEnable2FAForWithdraw: data.settings.totp_withdrawal_required === 1,
        isEnable2FAForTip: data.settings.totp_tip_required === 1,
      },
      authBy: mapperAuthType(data?.type),
    },
  }
}

export const FindUserDataMapper = (rawResponse: string): BaseResponse<RefUserProfile> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    data:
      response.data &&
      ({
        uid: response.data.uid,
        avatar: response.data.avatar_url,
        displayName: response.data.display_name,
        levelId: response.data.tier_id,
        registerCountry: response.data.country_code,
      } as RefUserProfile),
  }
}

export const PrepareSignMessageMapper = (rawResponse: string): BaseResponse<PrepareSignMessageResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    data: response.data && {
      address: response.data.address,
      acceptMessageText: response.data['accept_message_text'],
      acceptMessage: response.data['accept_message'],
      isRequire2FA: response.data['is_totp_required'] || false,
    },
  }
}

export const LoginResponseMapper = (rawResponse: string): BaseResponse<LoginResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      accessToken: response.data['access_token'],
      refreshToken: response.data['refresh_token'],
      uid: response.data['uid'] || null,
      isSignUp: !response.data['is_login'],
      husdBonusAmount: response.data['husd_bonus_amount'] || undefined,
      prepare2FAData: response.data['totp_prepare_data'] && {
        expireTime: response.data['totp_prepare_data'].expire_time,
        token: response.data['totp_prepare_data'].token,
      },
      username: response.data?.username,
      authBy: mapperAuthType(response.data?.type),
    },
  }
}

export const GetSignInSettingWalletMapper = (rawResponse: string): BaseResponse<SignInSettings> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'network_error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      isRequire2FA: response.data.is_totp_required,
    },
  }
}

export const PasswordNonceResponseMapper = (rawResponse: string): BaseResponse<PasswordNonceResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      ...response.data.password_nonce,
    },
  }
}

export const VerificationResponseMapper = (rawResponse: string): BaseResponse<VerificationResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      expireTime: response.data.expire_time * 1000,
      nextResentTime: response.data.next_resent_time * 1000,
      token: response.data.token,
    },
  }
}

export const RegisterMapper = (rawResponse: string): BaseResponse<RegisterResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      uid: response.data.uid,
      husdBonusAmount: response.data.husd_bonus_amount || undefined,
      username: response.data?.username,
      authBy: mapperAuthType(response.data?.type),
    },
  }
}

export const ResetPasswordVerificationResponseMapper = (rawResponse: string): BaseResponse<VerificationResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      expireTime: response.data.verification.data.expired_time * 1000,
      nextResentTime: response.data.verification.data.next_resent_time * 1000,
      token: response.data.verification.data.token,
    },
  }
}

export const NewPasswordNonceMapper = (rawResponse: string): BaseResponse<PasswordNonceResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      ...response.data.new_password_nonce,
      token: response.data.token,
    },
  }
}

export const PrepareTraditionalEnable2FAMapper = (rawResponse: string): BaseResponse<PrepareEnable2FA> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      expireTime: response.data.expire_time * 1000,
      key: response.data.key,
      url: response.data.url,
      sessionId: response.data.session_id,
      nextResentTime: response.data.email_next_re_send_time * 1000,
    },
  }
}

export const PrepareWalletEnable2FAMapper = (rawResponse: string): BaseResponse<PrepareWalletVerify2FAResponse> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      expireTime: response.data.expire_time * 1000,
      key: response.data.key,
      sessionId: response.data.session_id,
      url: response.data.url,
      acceptMessage: response.data.accept_message,
      acceptMessageText: response.data.accept_message_text,
      nextResentTime: response.data.next_resent_time,
    },
  }
}

export const PrepareWalletEnable2FAResendEmailMapper = (rawResponse): BaseResponse<Partial<VerificationResponse>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      nextResentTime: response.data.email_next_re_send_time * 1000,
    },
  }
}

export const PreparWeb3UserAddEmailMapper = (rawResponse): BaseResponse<Partial<VerificationResponse>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  return {
    code: response.code,
    message: response.message,
    errors: response.errors,
    data: response.data && {
      nextResentTime: response.data.next_resent_time * 1000,
      expireTime: response.data.expire_time * 1000,
      token: response.data.token,
    },
  }
}

