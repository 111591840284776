import { ContentImageData } from 'components/AlchemyContent/types/image'
import React from 'react'
import styled from 'styled-components'
import Flex from 'UIKit/Box/Flex'
import { sanitize } from 'utils/html'
import ContentBlockContainer from '../ContentBlockContainer'
import { StyledText } from '../Text'

export type ContentImageProps = {
  data: ContentImageData
}

const ContentImage: React.FC<ContentImageProps> = ({ data: { dataSrc, align, width, caption } }) => {
  return (
    dataSrc && (
      <ContentBlockContainer my="4px">
        <StyledImageContainer
          flexDirection="column"
          alignItems={align === 'left' ? 'flex-start' : align === 'right' ? 'flex-end' : align}
        >
          <StyledImageHolder width={['100%', , width]}>
            <img src={dataSrc} />
            <StyledText
              padding="0px 4px"
              fontSize="0.9em !important"
              textAlign={['center', , align]}
              color="#ADADAD !important"
              dangerouslySetInnerHTML={{ __html: sanitize(caption) }}
            />
          </StyledImageHolder>
        </StyledImageContainer>
      </ContentBlockContainer>
    )
  )
}

const StyledImageContainer = styled(Flex)`
  width: 100%;
`

const StyledImageHolder = styled(Flex)`
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
  }
`

export default ContentImage
