import { Address } from 'viem'
import { Chain } from 'viem/chains'
import 'eventemitter3'
import { Connector, InjectedConnectorOptions, WalletClient, WindowProvider } from '@wagmi/core'

type ConnectorOptions = InjectedConnectorOptions & Required<Pick<InjectedConnectorOptions, 'getProvider'>>

class TonConnector extends Connector<WindowProvider | undefined, ConnectorOptions> {
  readonly id: string = 'ton-connector'
  readonly name: string = 'Ton Provider'
  readonly ready: boolean
  protected shimDisconnectKey: string

  constructor({ chains, options: options_ }: { chains?: Chain[]; options?: any } = {}) {
    super({ chains, options: options_ })
    // Additional initialization if needed
  }

  connect(_props: { chainId?: number }): Promise<{
    account: `0x${string}`
    chain: {
      id: number
      unsupported: boolean
    }
  }> {
    return null
  }

  disconnect(): Promise<void> {
    return null
  }

  getAccount(): Promise<`0x${string}`> {
    return null
  }

  getChainId(): Promise<number> {
    return null
  }

  getProvider(): Promise<WindowProvider | undefined> {
    return null
  }

  getWalletClient(_props: { chainId?: number }): Promise<WalletClient> {
    return null
  }

  isAuthorized(): Promise<boolean> {
    return null
  }

  switchChain(_chainId: number): Promise<Chain> {
    return null
  }

  watchAsset({
    address,
    decimals,
    image,
    symbol,
  }: {
    address: Address
    decimals?: number
    image?: string
    symbol: string
  }): Promise<boolean> {
    return super.watchAsset({ address, decimals, image, symbol })
  }

  protected onAccountsChanged: (accounts: string[]) => void = (_accounts) => {}

  protected onChainChanged: (chainId: number | string) => void = (_chainId) => {}

  protected onDisconnect: (error: Error) => Promise<void> = async () => {}

  protected isUserRejectedRequestError(_error: unknown): boolean {
    return null
  }
}

export { TonConnector }
