import BigNumber from 'bignumber.js'
import { DISPLAY_AMOUNT } from 'config/constants'
import { ChainIdEnum } from 'config/constants/network'
import { HusdUnlockTxnStatusEnums } from 'config/constants/transactions'
import { Token, TokenAmount } from 'config/types'
import { BonusStatusEnums, CommonBonus } from 'config/types/bonus'
import { ProfileTierInfo } from 'config/types/profile'
import { PendingTransaction, TransactionStatusEnum } from 'config/types/transaction'
import Column, { ColumnCenter } from 'layout/Components/Column'
import { RowBetween, RowMiddle } from 'layout/Components/Row'
import React, { ReactNode, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useTiers } from 'state/app/hooks'
import { useUserSettings } from 'state/profile/hooks'
import styled from 'styled-components'
import { colors } from 'theme/colors'
import Box from 'UIKit/Box/Box'
import { BoxProps, FlexProps } from 'UIKit/Box/types'
import CopyButton from 'UIKit/Button/CopyButton'
import Link from 'UIKit/Link'
import Modal, { ModalBody } from 'UIKit/Modal'
import { TableRowValueHistory } from 'UIKit/Table/TableHistory'
import Text from 'UIKit/Text'
import { TextProps } from 'UIKit/Text/types'
import { TimeSince } from 'UIKit/TimeSince'
import DisplayTokenAmount from 'UIKit/TokenAmount'
import TokenAmountTooltip from 'UIKit/TokenAmount/TokenAmountTooltip'
import TokenLogo from 'UIKit/TokenLogo'
import { revalidateHunnyLevel, shortedAddress } from 'utils'
import { formatDisplayFullDateTime } from 'utils/dateHelper'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { getBlockExplorerUrls } from 'utils/network'
import { getTokenName } from 'utils/token'
import { v4 as uuidv4 } from 'uuid'
import { ValidatedBonusStatusEnums, buildBonusName, useValidateBonus } from 'views/BonusCenter/hooks'
import TokenAmountFiatDisplay from 'views/Fiat/TokenAmountFiatDisplay'
import USDAmountDisplay from 'views/Fiat/USDAmountDisplay'

export const FieldTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.textSubtle};
  font-size: ${({ fontSize }) => fontSize || '1em'};
  line-height: 1.4;
`

const StyledLink = styled(Text)`
  &:hover {
    text-decoration: underline;
  }
`

const StyledContent = styled(Text)`
  cursor: pointer;
`

export const WrapperRowHistory = styled(Box)`
  border-radius: ${({ theme: { radii } }) => radii.tiny};
  padding: 12px 8px;
  font-size: 14px;
  &:hover {
    background: ${({ theme }) => theme.colors.backgroundAlt5};
  }
`

export const WrapperContainerTransactionDetail = styled(Box)`
  font-size: 14px;
  border: 1px solid #232c3d8e;
  border-radius: ${({ theme }) => theme.radii.tiny};
  padding: 12px;
`

export const DisplayAmount = ({
  tokenAmount,
  hideTokenName,
  ...props
}: {
  tokenAmount: TokenAmount
  hideTokenName?: boolean
} & BoxProps) => {
  const {
    userSettings: { isDisplayInFiat },
  } = useUserSettings()

  return (
    <Box my="12px" {...props}>
      {isDisplayInFiat ? (
        <ColumnCenter>
          <Text fontSize="24px" fontWeight={900}>
            <TokenAmountFiatDisplay tokenAmount={tokenAmount} />
          </Text>

          <DisplayTokenAmount tokenAmount={tokenAmount} mt="7px" />
        </ColumnCenter>
      ) : (
        <RowMiddle alignItems="flex-start !important" my="12px">
          <RowMiddle minHeight="24px">
            <TokenLogo
              token={tokenAmount.token}
              size={20}
              minWidth={20}
              maxWidth={20}
              networkImageProps={{
                width: 12,
                height: 12,
              }}
            />
          </RowMiddle>
          <Text
            as="span"
            fontSize="20px"
            fontWeight={800}
            ml="4px"
            textAlign="left"
            style={{
              wordBreak: 'break-word',
            }}
            lineHeight={1.2}
            letterSpacing="-0.48px"
          >
            {getFullDisplayBalance(BigNumber(tokenAmount.amount), 0, 8)}
            {!hideTokenName && (
              <Text
                as="span"
                color="textAlt1"
                fontSize="1em"
                fontWeight={800}
                lineHeight={1.2}
                letterSpacing="-0.48px"
                ml="4px"
                style={{
                  wordBreak: 'keep-all',
                }}
              >
                {getTokenName(tokenAmount.token)}
              </Text>
            )}
          </Text>
        </RowMiddle>
      )}
    </Box>
  )
}

export const SectionTransactionAmount: React.FC<
  React.PropsWithChildren<{
    title: string
    token: Token
    amount: string
    date?: number
    hideTokenName?: boolean
  }>
> = ({ title, token, amount, date, hideTokenName, children }) => {
  const { t } = useTranslation()

  return (
    <WrapperContainerTransactionDetail>
      <ColumnCenter>
        <Text color="textSubtle" fontSize="1em" fontWeight={600}>
          <Trans>{title}</Trans>
        </Text>

        <DisplayAmount
          tokenAmount={{
            token,
            amount: BigNumber(amount),
          }}
          hideTokenName={hideTokenName}
        />
      </ColumnCenter>

      <Column mt="12px">{children}</Column>

      {date && (
        <>
          <Box
            height="1px"
            width="100%"
            style={{
              background: '#232c3d8e',
            }}
            my="12px"
          />
          <RowBetween>
            <Text fontSize="1em" color="textAlt1">
              {t('Date')}
            </Text>
            <Text textAlign="right" fontWeight={300} fontSize="1em" color="textAlt1">
              {formatDisplayFullDateTime(new Date(date))}
            </Text>
          </RowBetween>
        </>
      )}
    </WrapperContainerTransactionDetail>
  )
}

export const RowDisplayCurrencyAmountSymbolItem: React.FC<
  {
    token: Token
    value: string
    size?: number
    networkSize?: number
    hideLogo?: boolean
    additionalIcon?: ReactNode
  } & FlexProps
> = ({ token, value, size = 32, networkSize = 16, hideLogo = false, additionalIcon, ...props }) => {
  const {
    userSettings: { isDisplayInFiat },
  } = useUserSettings()

  const tooltipId = useMemo(() => uuidv4(), [])

  return (
    <RowMiddle {...props}>
      {!hideLogo && (
        <TokenLogo
          mr="8px"
          token={token}
          size={size}
          minWidth={size}
          networkImageProps={{ width: networkSize, height: networkSize }}
        />
      )}
      <Column>
        <StyledDisplayAmount lineHeight="14px" textAlign={['right', 'right', 'left']}>
          {isDisplayInFiat ? (
            <Box>
              <TableRowValueHistory
                as="span"
                symbol
                ml="4px"
                fontWeight={600}
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                data-tooltip-id={tooltipId}
              >
                {additionalIcon}

                {token?.code === 'USD' ? (
                  <USDAmountDisplay amount={value} />
                ) : (
                  <TokenAmountFiatDisplay tokenAmount={{ token, amount: BigNumber(value) }} />
                )}
              </TableRowValueHistory>
              <TokenAmountTooltip
                tokenAmount={{ token, amount: BigNumber(value) }}
                border="none"
                tooltipId={tooltipId}
              />
            </Box>
          ) : (
            <>
              {token?.code === 'USD' ? (
                <USDAmountDisplay amount={value} />
              ) : (
                <TokenAmountFiatDisplay tokenAmount={{ token, amount: BigNumber(value) }} limitDisplayDecimals={8} />
              )}

              <TableRowValueHistory className="currency-symbol" as="span" symbol ml="4px" fontWeight={600}>
                {getTokenName(token)}
              </TableRowValueHistory>
            </>
          )}
        </StyledDisplayAmount>
      </Column>
    </RowMiddle>
  )
}

const StyledDisplayAmount = styled(TableRowValueHistory)`
  line-height: 24px;

  .arrow__display__amount__tip {
    background: #222a36 !important;
  }
`
const StyledModal = styled(Modal)`
  #modal-closebutton {
    top: 20px;
  }
`
export const TransactionWrapperModal: React.FC<
  React.PropsWithChildren<
    {
      onDismiss: () => void
      title: string
      bodyProps?: BoxProps
    } & BoxProps
  >
> = ({ title, onDismiss, children, bodyProps, ...props }) => {
  return (
    <StyledModal
      maxWidth={['', '', '420px !important']}
      minWidth={['', '', '420px !important']}
      p="24px 24px 40px 24px"
      onDismiss={onDismiss}
      {...props}
    >
      <Text as="h2" fontSize="16px" fontWeight={600}>
        {title}
      </Text>

      <ModalBody
        borderRadius="0 !important"
        mt="24px"
        height="100%"
        style={{
          overflowY: 'unset',
          ...bodyProps?.style,
        }}
        {...bodyProps}
      >
        {children}
      </ModalBody>
    </StyledModal>
  )
}

export const TransactionStatus: React.FC<{ status: TransactionStatusEnum; instruction?: string } & TextProps> = ({
  status,
  instruction,
  ...props
}) => {
  return (
    <>
      {PendingTransaction.includes(status) ? (
        <StyledPendingText
          color="warning"
          fontSize="14px"
          lineHeight="20px"
          $isExistInstruction={!!instruction}
          {...props}
        >
          <Trans>Pending</Trans>
        </StyledPendingText>
      ) : status === TransactionStatusEnum.Succeeded ? (
        <Text color="success" fontSize="14px" lineHeight="20px" {...props}>
          <Trans>Success</Trans>
        </Text>
      ) : status === TransactionStatusEnum.Expired ? (
        <Text color="error" fontSize="14px" lineHeight="20px" {...props}>
          <Trans>Expired</Trans>
        </Text>
      ) : status === TransactionStatusEnum.Processing ? (
        <Text color="warning" fontSize="14px" lineHeight="20px" {...props}>
          <Trans>Processing</Trans>
        </Text>
      ) : (
        <Text color="error" fontSize="14px" lineHeight="20px" {...props}>
          <Trans>Failed</Trans>
        </Text>
      )}
    </>
  )
}

const StyledPendingText = styled(Text)<{ $isExistInstruction: boolean }>`
  position: relative;
  ${({ $isExistInstruction }) =>
    $isExistInstruction &&
    `&::before {
    display: block;
    content: '';
    background-color: ${colors.strokeAlt3};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: -6px;
    right: -10px;
  }`};
`

export const BonusStatus: React.FC<{ bonus: CommonBonus }> = ({ bonus }) => {
  const validatedResult: ValidatedBonusStatusEnums = useValidateBonus(bonus)

  return (
    <TableRowValueHistory width="max-content">
      <Trans>
        {bonus.status === BonusStatusEnums.Active && validatedResult === ValidatedBonusStatusEnums.Available
          ? 'Active'
          : validatedResult === ValidatedBonusStatusEnums.Expired
          ? 'Expired'
          : validatedResult === ValidatedBonusStatusEnums.Cancelled
          ? 'Cancelled'
          : validatedResult === ValidatedBonusStatusEnums.Claimed || validatedResult === ValidatedBonusStatusEnums.Used
          ? 'Completed'
          : validatedResult === ValidatedBonusStatusEnums.Available || bonus.status === BonusStatusEnums.Available
          ? 'Available'
          : validatedResult === ValidatedBonusStatusEnums.Finish || bonus.status === BonusStatusEnums.Finish
          ? 'Finished'
          : '-'}
      </Trans>
    </TableRowValueHistory>
  )
}

export const RankingBonusName: React.FC<{ level: number }> = ({ level }) => {
  const tiers = useTiers()
  const { t } = useTranslation()

  const profileInfoDetail: ProfileTierInfo = useMemo(() => tiers.find((tier) => tier.level === level), [tiers, level])
  const levelDisplay = profileInfoDetail
    ? t('VIP {{levelDisplay}} bonus', {
        levelDisplay: revalidateHunnyLevel(profileInfoDetail.level),
      })
    : '_'

  return <TableRowValueHistory>{levelDisplay}</TableRowValueHistory>
}

export const BonusName: React.FC<{ bonus: CommonBonus }> = ({ bonus }) => {
  const { t } = useTranslation()
  return <>{buildBonusName(t, bonus?.info?.name)[0]}</>
}

export const HusdUserStatus: React.FC<{ status: HusdUnlockTxnStatusEnums } & TextProps> = ({ status, ...props }) => {
  return (
    <TableRowValueHistory {...props}>
      <Trans>{status === HusdUnlockTxnStatusEnums.Claimed ? 'Claimed' : 'Received'}</Trans>
    </TableRowValueHistory>
  )
}

export const getHusdStatus = (status: HusdUnlockTxnStatusEnums) => {
  return status !== HusdUnlockTxnStatusEnums.Claimed && HusdUnlockTxnStatusEnums[status]
    ? status === HusdUnlockTxnStatusEnums.Promotion
      ? 'Deposit'
      : status === HusdUnlockTxnStatusEnums.LuckySpin
      ? 'Lucky Spin'
      : status === HusdUnlockTxnStatusEnums.Reward
      ? 'Reward'
      : status === HusdUnlockTxnStatusEnums.VoucherReward
      ? 'Bonus Reward'
      : status === HusdUnlockTxnStatusEnums.SignUpBonus
      ? 'SignUp Bonus'
      : status === HusdUnlockTxnStatusEnums.Airdrop
      ? 'Airdrop'
      : status === HusdUnlockTxnStatusEnums.DailyCheckin
      ? 'Daily Checkin'
      : HusdUnlockTxnStatusEnums.SurveySignUp
      ? 'Survey Reward'
      : HusdUnlockTxnStatusEnums[status]
    : null
}

export const HusdStatusTxnHistory: React.FC<{ status: HusdUnlockTxnStatusEnums } & TextProps> = ({
  status,
  ...props
}) => {
  const { t } = useTranslation()
  const husdStatus = getHusdStatus(status)
  return <TableRowValueHistory {...props}>{husdStatus ? t(husdStatus) : '-'}</TableRowValueHistory>
}

export const TransactionHash: React.FC<
  { txnHash: string; chainId: ChainIdEnum; hideHyperLink?: boolean } & FlexProps
> = ({ txnHash, chainId, hideHyperLink, ...props }) => {
  const blockExplorerUrls = getBlockExplorerUrls({ chainId, txnHash })

  return (
    <RowMiddle {...props}>
      {!hideHyperLink && (
        <StyledLink
          mt={txnHash ? '6px' : ''}
          as={Link}
          href={blockExplorerUrls}
          external
          mr="8px"
          fontSize="1em"
          lineHeight="1em"
        >
          <TableRowValueHistory lineHeight="14px">{txnHash ? shortedAddress(txnHash, 4, 4) : '_'}</TableRowValueHistory>
        </StyledLink>
      )}
      {hideHyperLink && (
        <StyledContent mt={txnHash ? '6px' : ''} mr="8px" fontSize="1em" lineHeight="1em">
          <TableRowValueHistory lineHeight="14px">{txnHash ? shortedAddress(txnHash, 4, 4) : '_'}</TableRowValueHistory>
        </StyledContent>
      )}
      {txnHash && <CopyButton text={txnHash} width="20px" height="20px" />}
    </RowMiddle>
  )
}

export const TransactionDate: React.FC<
  { date: number; formatTime?: (date: Date) => string } & Omit<TextProps, 'display'>
> = ({ date, formatTime, ...props }) => {
  return (
    <TimeSince
      textAlign="right"
      formatDisplay={formatTime || formatDisplayFullDateTime}
      date={new Date(date)}
      color="textTertiary"
      fontSize="14px"
      hideTooltip
      {...props}
    />
  )
}

export const formatTxnNumber = (number: BigNumber | number | string) => {
  const bigNumber = new BigNumber(number)

  if (bigNumber.mod(1).eq(0) || bigNumber.eq(0)) {
    const strNumber = number.toString()
    return {
      main: strNumber,
      sub: '.'.padEnd(10 - strNumber.length, '0'),
    }
  }

  if (bigNumber.lt('0.00000001')) {
    return {
      main: bigNumber.toString(10),
      sub: '',
    }
  }

  const fraction = bigNumber.toString(10).split('.')
  if (fraction[0].length >= 9) {
    return {
      main: fraction[0],
      sub: '',
    }
  }

  const strNumber = Number(`${fraction[0]}.${fraction[1].toString().slice(0, 9 - fraction[0].length)}`).toString()

  return {
    main: strNumber,
    sub: ''.padEnd(10 - strNumber.length, '0'),
  }
}

export const DisplayNumberFullFixed: React.FC<{ amount: string } & TextProps> = ({ amount, color, ...props }) => {
  // const display = formatTxnNumber(amount)

  const display = getFullDisplayBalance(new BigNumber(amount), 0, DISPLAY_AMOUNT)
  return (
    <Text as="span" color={display ? color : 'textAlt1'} letterSpacing="-0.28px" fontSize="1em" {...props}>
      {display}
    </Text>
    // <Text
    //   fontWeight={600}
    //   as="span"
    //   color={display ? color : 'textAlt1'}
    //   letterSpacing="-0.28px"
    //   fontSize="1em"
    //   {...props}
    // >
    //   {display.main}
    //   <Text fontWeight={600} as="span" color="textAlt1" letterSpacing="-0.28px" fontSize="1em" {...props}>
    //     {display.sub}
    //   </Text>
    // </Text>
  )
}

export const DisplayMultiplier: React.FC<{ amount: string | number | BigNumber } & TextProps> = ({
  amount,
  ...props
}) => {
  return (
    <Text fontWeight={600} as="span" letterSpacing="-0.28px" fontSize="1em" {...props}>
      {new BigNumber(amount.toString(10)).toFixed(2)}x
    </Text>
  )
}
