import { Time } from 'config/constants'
import { buildPluralizeText, defaultTimeDisplayConfig } from 'utils'

export const displayUserRegistrationDay = (data: any, t) => {
  let time = 1
  let timeUnit = 'day'
  let timeFollowing = 0
  let timeFollowingUnit = ''

  if (Time.YEAR in data) {
    time = data[Time.YEAR][0]
    timeUnit = buildPluralizeText('{{unit}}', [
      { key: 'unit', word: defaultTimeDisplayConfig[Time.YEAR], number: time },
    ])
    timeFollowing = data?.[Time.MONTH]?.[0]
    timeFollowingUnit = data?.[Time.MONTH]?.[0]
      ? buildPluralizeText('{{unit}}', [
          { key: 'unit', word: defaultTimeDisplayConfig[Time.MONTH], number: timeFollowing || 0 },
        ])
      : ''
  } else if (Time.MONTH in data) {
    time = data[Time.MONTH][0]
    timeUnit = buildPluralizeText('{{unit}}', [
      { key: 'unit', word: defaultTimeDisplayConfig[Time.MONTH], number: time },
    ])
    timeFollowing = data?.[Time.DAY]?.[0]
    timeFollowingUnit = data?.[Time.DAY]?.[0]
      ? buildPluralizeText('{{unit}}', [
          { key: 'unit', word: defaultTimeDisplayConfig[Time.DAY], number: timeFollowing || 0 },
        ])
      : ''
  } else if (Time.DAY in data) {
    time = data[Time.DAY]?.[0]
    timeUnit = buildPluralizeText('{{unit}}', [{ key: 'unit', word: defaultTimeDisplayConfig[Time.DAY], number: time }])
  }

  return t(`{{time}} ${timeUnit} {{timeFollowing}} ${timeFollowingUnit} on the platform`, {
    time,
    timeFollowing: timeFollowing || '',
  })
}
