import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Button from 'UIKit/Button'
import Image from 'UIKit/Image'
import CircleLoader from 'UIKit/Loader/CircleLoader'
import Modal, { ModalProps } from 'UIKit/Modal'
import ModalBody from 'UIKit/Modal/components/ModalBody'

import Text from 'UIKit/Text'
import { WalletInfo } from 'config/types/wallet'
import useSignMessage from 'hooks/useSignMessage'
import useWalletAccount from 'hooks/useWalletAccount'
import { RowMiddle } from 'layout/Components/Row'
import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useAuth, useSignAuthMessage } from 'state/auth/hooks'
import styled from 'styled-components'
import { shortedAddress } from 'utils'

type SignInByWalletProps = {
  wallet: WalletInfo
  signUpPromoCode?: string
}
const SignInByWallet: React.FC<ModalProps<SignInByWalletProps>> = ({ data, onDismiss }) => {
  const { wallet, signUpPromoCode } = data

  const accounts = useWalletAccount()
  const signMessage = useSignMessage(wallet.type)
  const [signing, setSigning] = useState(false)

  const { isSigned } = useAuth()
  const address = accounts[wallet.type]
  const { handleSignMessageSuccess, handleGetUserSignInSettings, getMessageToWalletSign } = useSignAuthMessage()

  useEffect(() => {
    if (!address || isSigned) {
      onDismiss()
    }
  }, [address, wallet, isSigned])

  const handleSignWallet = async () => {
    setSigning(true)

    const { acceptMessageText, acceptMessage, tokenToSignMessage } = await getMessageToWalletSign({
      address,
      walletType: wallet.type,
    })

    const { messageSignature, tonProof } = await signMessage(acceptMessageText)
    if (!(messageSignature || tonProof)) {
      return
    }

    const { isRequire2FA } = await handleGetUserSignInSettings({
      address,
      network: tokenToSignMessage.network,
    })

    handleSignMessageSuccess({
      acceptMessage,
      isRequire2FA,
      messageSignature,
      tonProof,
      address,
      signUpPromoCode,
      wallet,
    })

    setSigning(false)
  }

  return (
    <StyledModal id="popup-signwallet" onDismiss={onDismiss} p="0 !important">
      <ModalBody>
        <Box p="40px 34px">
          <Flex alignItems="center" flexDirection="column">
            {signing ? (
              <Image alt="join" width={80} height={80} src="/images/signing.png" />
            ) : (
              <Image alt="join" width={80} height={80} src="/images/join.png" />
            )}
            <Text bold fontSize="18px" lineHeight="24px" mt="16px">
              <Trans>Signing with this address</Trans>
            </Text>
            <Text bold fontSize="18px" mt="2px">
              {shortedAddress(address)}
            </Text>

            <Text color="textSubtle" fontSize="14px" lineHeight={1.2} mt="28px" textAlign="center">
              <Trans>
                You need to sign a message on your wallet to prove ownership of the address you are connected with.
              </Trans>
            </Text>

            <StyledButton id="signmessage-button" width="100%" mt="40px" onClick={handleSignWallet} disabled={signing}>
              {signing ? (
                <RowMiddle>
                  <Box mr="8px">
                    <CircleLoader />
                  </Box>
                  <Trans>Waiting for Signature</Trans>
                </RowMiddle>
              ) : (
                <Trans>Sign Message</Trans>
              )}
            </StyledButton>
          </Flex>
        </Box>
      </ModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 440px !important;
    max-width: 440px !important;
  }
`

const StyledButton = styled(Button)`
  font-size: 12px;
  font-weight: bold;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.primaryDisabled};
  }
`

export default SignInByWallet
