import { AuthMethod } from 'config/constants/auth'
import { ChainIdEnum } from 'config/constants/network'
import tokens, { HUSD_TOKEN } from 'config/constants/tokens'
import { WALLET_INFO } from 'config/constants/wallet'
import { Token } from 'config/types'
import { LoginPayload } from 'config/types/auth'
import { RefUserProfile } from 'config/types/profile'
import { WalletInfo, WalletType } from 'config/types/wallet'
import { Context } from 'contexts/Modals'
import { EventKey, useAnalytics } from 'hooks/useAnalytics'
import useAuthenticationModal from 'hooks/useAuthenticationModal'
import { ConnectRequestAdditionalData, useConnectWallet } from 'hooks/useConnectWallet'
import useModal from 'hooks/useModal'
import useNewUserJourney from 'hooks/useNewUserJourney'
import useQueryParam from 'hooks/useQueryParam'
import { useReferralCode } from 'hooks/useReferralCode'
import { useRequest } from 'hooks/useRequest'
import { useRouter } from 'hooks/useRouter'
import useSuggestedLogin from 'hooks/useSuggestedLogin'
import { useBuildTeleAppData } from 'hooks/useTelegram'
import useWalletAccount from 'hooks/useWalletAccount'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AuthenticationService from 'services/AuthenticationService'
import { AuthRefreshHelper } from 'services/AuthRefreshHelper'
import { SignInByWalletPayload, TonProof } from 'services/types'
import { useAppDispatch, useAppSelector } from 'state'
import { setIsSigned } from 'state/app/actions'
import { resetUserBonus } from 'state/bonus/actions'
import { updateAvailableCashbacks, updateTotalEarnedCashbacks } from 'state/cashback/actions'
import { useUnreadNotificationAmount } from 'state/notification/hooks'
import { resetProfile } from 'state/profile/actions'
import {
  useProfileTier,
  useUpdateFavoriteGames,
  useUpdateWageringBonusFeaturedGames,
  useUserOnboardingStatus,
} from 'state/profile/hooks'
import { updateDeviceUid } from 'state/session/actions'
import { formatToApiNetworkField } from 'utils'
import { getID } from 'utils/fingerprint'
import { isSolChain, isTonChain } from 'utils/network'
import { SentryHelper } from 'utils/sentryHelper'
import { getHUSDBonus, removeHUSDBonusStorage } from 'utils/signupBonus'
import { HunnyToast } from 'utils/toastify'
import { getTrackingWalletName } from 'utils/trackingWallet'
import WalletAuthModal from 'views/Authentication/components/Wallet/WalletAuthModal'
import { AuthModalMethodEnums, AuthModalPageEnums } from 'views/Authentication/types'
import ProvideTotpAuthCodeModal from 'views/Profile/Settings/components/SettingSecurity/2FA/ProvideTotpAuthCodeModal'
import { login, logout, updateWallet } from './action'

export const useLogout = () => {
  const clientLogout = useClientLogout()
  const wallet = useAppSelector((state) => state.auth.wallet)
  const disconnect = useConnectWallet()

  return useCallback(async () => {
    const isLogout = await AuthenticationService.logout()

    if (isLogout) {
      if (disconnect?.[wallet?.type]) {
        await disconnect[wallet.type].disconnect()
      }

      clientLogout()
    }
  }, [wallet, disconnect])
}

export const useClientLogin = () => {
  const dispatch = useAppDispatch()

  const { recordEvent } = useAnalytics()
  const { clearSuggessLoginInfo } = useSuggestedLogin()

  const handleLogin = useCallback(
    (payload: LoginPayload, deviceUid: string, trackingInfo: { event: EventKey } = { event: 'login' }) => {
      clearSuggessLoginInfo()
      recordEvent(
        trackingInfo.event,
        {
          loginType: payload.authBy,
        },
        payload.uid,
      )

      AuthRefreshHelper.captureSignedAtTime()

      dispatch(updateDeviceUid({ deviceUid }))
      dispatch(login({ payload }))
      dispatch(setIsSigned({ isSigned: true }))
    },
    [],
  )

  return handleLogin
}

export const useClientLogout = () => {
  const dispatch = useAppDispatch()
  const { updateUnreadPrivateAmount, updateUnreadSystemAmount } = useUnreadNotificationAmount()
  const { update: updateUserFavoriteGames } = useUpdateFavoriteGames()
  const { update: updateWageringBonusGameCodes } = useUpdateWageringBonusFeaturedGames()

  return useCallback(async () => {
    SentryHelper.addBreadcrumbs({
      category: 'logout',
      message: 'Logout user from client',
    })

    await dispatch(logout())
    await dispatch(resetProfile())
    await dispatch(resetUserBonus())
    await dispatch(setIsSigned({ isSigned: false }))
    await dispatch(updateTotalEarnedCashbacks({ totalEarnedCashbacks: [] }))
    await dispatch(updateAvailableCashbacks({ availableCashbacks: [] }))
    await updateUnreadPrivateAmount(0)
    await updateUnreadSystemAmount(0)
    await removeHUSDBonusStorage()
    await updateUserFavoriteGames([])
    await updateWageringBonusGameCodes([])
    AuthRefreshHelper.reset()
  }, [])
}

export const useAuthRefresh = () => {
  const dispatch = useAppDispatch()
  const { isAuthByWallet } = useAuthBy()

  const logout = useClientLogout()

  const [onPresentAuthModal] = useAuthenticationModal()
  const { closeModal } = useContext(Context)

  const { t } = useTranslation()

  const onRefreshFailed = useCallback(async () => {
    closeModal()

    const msg = 'Your session expired. Please login again'
    HunnyToast.warn(t(msg))
    await logout()

    onPresentAuthModal({
      page: AuthModalPageEnums.LOG_IN,
      method: isAuthByWallet ? AuthModalMethodEnums.WEB3 : AuthModalMethodEnums.TRADITIONAL,
    })
  }, [isAuthByWallet, onPresentAuthModal, logout, closeModal])

  const refresh = useCallback(async () => {
    if (AuthRefreshHelper.IsInitialized) {
      const result = await AuthRefreshHelper.refresh()
      if (result?.code == 'success') {
        dispatch(setIsSigned({ isSigned: true }))
      }
    } else {
      onRefreshFailed()
    }
  }, [onRefreshFailed])

  return { refresh, onRefreshFailed }
}

export const useAuth = () => {
  const { authBy, uid, username, hasSession, deviceUid } = useUserInfo()
  const isSigned = useAppSelector((state) => state.app?.isSigned)

  return useMemo(
    () => ({ isSigned, username, uid, authBy, hasSession, deviceUid }),
    [deviceUid, isSigned, username, uid, authBy, hasSession],
  )
}

export const useAuthBy = () => {
  const { authBy, hasSession } = useUserInfo()

  return useMemo(() => {
    const isAuthByEmail = hasSession && authBy === AuthMethod.Email
    const isAuthByWallet = hasSession && authBy === AuthMethod.Wallet
    const isAuthByTelegram = hasSession && authBy === AuthMethod.Telegram

    return {
      isAuthByTelegram,
      isAuthByEmail,
      isAuthByWallet,
    }
  }, [authBy, hasSession])
}
export const useUserInfo = () => {
  const authBy = useAppSelector((state) => state.auth?.authBy)
  const deviceUid = useAppSelector((state) => state.session?.deviceUid)
  const uid = useAppSelector((state) => state.auth?.uid)
  const username = useAppSelector((state) => state.auth?.username)
  const displayName = useAppSelector((state) => state.profile?.displayName)
  const avatar = useAppSelector((state) => state.profile?.avatar)
  const canUpdateDisplayNameAt = useAppSelector((state) => state.profile?.canUpdateDisplayNameAt)
  const userCode = useAppSelector((state) => state.profile?.userCode)
  const registeredDate = useAppSelector((state) => state.profile?.registeredDate)

  return useMemo(
    () => ({
      username,
      uid,
      authBy,
      deviceUid,
      hasSession: !!(username && uid && deviceUid),
      displayName: displayName || username,
      avatar,
      canUpdateDisplayNameAt,
      userCode,
      registeredDate,
    }),
    [username, uid, authBy, displayName, avatar, canUpdateDisplayNameAt, userCode, deviceUid],
  )
}

export const useRefMyProfile = (): RefUserProfile => {
  const { avatar, displayName } = useUserInfo()
  const { id } = useProfileTier()

  return useMemo(() => ({ avatar, displayName, levelId: id }), [avatar, displayName, id])
}

export const useWalletInfo = () => {
  const dispatch = useAppDispatch()
  const { wallet } = useAppSelector((state) => state.auth)
  const accounts = useWalletAccount()
  const [onPresentAuthModal, onDimiss] = useModal(WalletAuthModal)
  const { t } = useTranslation()
  const walletConnector = useConnectWallet()

  const walletInfo = useMemo(
    () =>
      wallet &&
      WALLET_INFO.find((item) => (wallet.name ? wallet.name === item.name : true) && item.type === wallet.type),
    [wallet],
  )

  const onCallbackConnect = useCallback(
    async ({
      account,
      wallet: walletReconnect,
      callbackReconnect,
    }: {
      account: string
      wallet: WalletInfo
      callbackReconnect?: () => void
    }) => {
      if (!walletReconnect || walletReconnect.type !== wallet.type || !account || !wallet.address) {
        HunnyToast.error(t('The wallet is incorrect. Please check your wallet again.'))
        return
      }

      const trackingName =
        walletReconnect.type === WalletType.SOL ? walletReconnect.name : await getTrackingWalletName(walletReconnect)

      dispatch(
        updateWallet({
          wallet: walletReconnect,
          trackingName,
        }),
      )

      if (callbackReconnect) callbackReconnect()

      onDimiss()
    },
    [wallet, t],
  )

  const onConnectByWalletType = useCallback(
    (data?: ({ callbackReconnect?: () => void } & ConnectRequestAdditionalData) | any) => {
      const walletByName =
        wallet && WALLET_INFO.find((item) => wallet?.name === item?.name && item.type === wallet?.type)

      const walletWithConnector =
        wallet && WALLET_INFO.find((item) => wallet?.connectorId === item?.adapter?.name && item.type === wallet?.type)

      if ((walletByName?.adapter || walletWithConnector?.adapter) && walletConnector?.[wallet?.type]) {
        walletConnector[wallet.type].connect(walletByName?.adapter || walletWithConnector?.adapter, data)
      } else {
        onPresentAuthModal({
          onCallbackConnect: (connectResponse) =>
            onCallbackConnect({
              ...connectResponse,
              callbackReconnect: data.callbackReconnect,
            }),
        })
      }
    },
    [onCallbackConnect, wallet, walletConnector],
  )

  return useMemo(() => {
    const account = accounts[wallet?.type]
    const isWrongAddress = account && account.toLowerCase() !== wallet.address.toLowerCase() && account?.length
    const isEmptyAddress = !account?.length

    return {
      wallet,
      walletInfo,
      isWrongAddress,
      isEmptyAddress,
      account,
      onConnectByWalletType,
    }
  }, [walletInfo, onConnectByWalletType, wallet, accounts])
}

export const useDetectInvalidAddressAndDisconnectTon = (detectCallback?: () => void) => {
  const { isWrongAddress, walletInfo } = useWalletInfo()
  const handle = useConnectWallet()

  useEffect(() => {
    const isTonWrongAddress = isWrongAddress && walletInfo.type === WalletType.TON
    if (isTonWrongAddress) {
      handle[WalletType.TON].disconnect()

      if (detectCallback) {
        detectCallback()
      }
    }
  }, [isWrongAddress, walletInfo])

  return useMemo(
    () => ({ isWrongTonAddress: isWrongAddress && walletInfo.type === WalletType.TON }),
    [isWrongAddress, walletInfo],
  )
}

export const useSignAuthMessage = () => {
  const refCode = useReferralCode()
  const router = useRouter()
  const stateWallet = useAppSelector((state) => state.auth.wallet)
  const tokenInfoes = useAppSelector((state) => state.app.tokenInfoes)

  const { removeParam } = useQueryParam()

  const { execute } = useRequest()
  const clientLogin = useClientLogin()

  const bonus = getHUSDBonus()
  const startJourney = useNewUserJourney()
  const { update } = useUserOnboardingStatus()
  const { webAppData } = useBuildTeleAppData()
  const [onPresentProvideTotpCodeModal] = useModal(ProvideTotpAuthCodeModal)

  const getMessageToWalletSign = async ({ address, walletType }: { address?: string; walletType: WalletType }) => {
    const _walletType = walletType || stateWallet?.type
    const tokenKey = tokenInfoes.filter((token) => {
      if (_walletType === WalletType.SOL) {
        return isSolChain(token.network)
      }
      if (_walletType === WalletType.TON) {
        return isTonChain(token.network)
      }
      return token
    })?.[0]

    const tokenToSignMessage: Token =
      (tokenKey && tokens[tokenKey.network] && tokens[tokenKey.network][tokenKey.token]) || HUSD_TOKEN

    const {
      data: { acceptMessageText, acceptMessage, isRequire2FA },
    } = await execute(
      AuthenticationService.getMessageToWalletSign({ address, walletType, currency: tokenToSignMessage }),
    )

    return {
      acceptMessageText,
      acceptMessage,
      isRequire2FA,
      tokenToSignMessage,
    }
  }

  const handleSignIn = async ({
    address,
    messageSignature,
    acceptMessage,
    totpAuthCode,
    signUpPromoCode,
    tonProof,
    wallet,
  }: {
    address: string
    messageSignature: string
    acceptMessage: string
    wallet: WalletInfo
    totpAuthCode?: string
    signUpPromoCode?: string
    tonProof?: TonProof
  }) => {
    const deviceUid = await getID()
    const params: SignInByWalletPayload = {
      deviceUid,
      address,
      messageSignature,
      acceptMessage,
      totpAuthCode,
      webAppData,
      tonProof,
    }

    if (refCode.type === 'affilka') {
      params.affilkaCode = refCode.refCode
    } else if (refCode.type === 'hunnyplay') {
      params.referralCode = refCode.refCode
    }
    if (bonus) {
      params.husdBonusAmount = bonus.amount || 0
    }

    const result = await AuthenticationService.signInByWallet(params)

    if (result.data?.uid) {
      const walletTracked = wallet.type === WalletType.SOL ? wallet.name : await getTrackingWalletName(wallet)
      clientLogin(
        {
          authBy: result.data.authBy,
          uid: result.data.uid,
          username: address,
          wallet: {
            name: wallet.name,
            type: wallet.type,
            connectorId: wallet.adapter.name,
            address,
            trackingName: walletTracked,
          },
          email: null,
        },
        deviceUid,
        { event: result.data.isSignUp ? 'register' : 'login' },
      )

      if (result.data?.isSignUp) {
        update(true)
        const { bonus } = router.query

        startJourney({
          husdBonusAmount: result?.data?.husdBonusAmount,
          bonusCode: bonus?.toString() || signUpPromoCode,
        })
        removeParam('bonus')
      }
    }

    return result
  }

  const handleGetUserSignInSettings = async ({
    network,
    address,
  }: {
    network: string | number
    address: string
  }): Promise<{ isRequire2FA: boolean }> => {
    const payloadNetwork = formatToApiNetworkField(typeof network === 'number' ? ChainIdEnum[network] : network)
    const defaultResponse = {
      isRequire2FA: false,
    }

    try {
      const response = await execute(
        AuthenticationService.getSignInByWalletSettings({ network: payloadNetwork, address }),
      )

      if (response.data) {
        return { isRequire2FA: response.data.isRequire2FA }
      }

      return defaultResponse
    } catch (error) {
      console.error(error)
      return defaultResponse
    }
  }

  const handleSignMessageSuccess = async ({
    messageSignature,
    tonProof,
    acceptMessage,
    isRequire2FA,
    address,
    signUpPromoCode,
    wallet,
  }: {
    messageSignature: string
    tonProof: TonProof
    acceptMessage: string
    isRequire2FA: boolean
    address: string
    signUpPromoCode?: string
    wallet: WalletInfo
  }) => {
    if (isRequire2FA) {
      onPresentProvideTotpCodeModal({
        onTotpAuthCodeConfirmed: async (code) => {
          const response = await handleSignIn({
            messageSignature,
            acceptMessage,
            tonProof,
            totpAuthCode: code,
            address,
            signUpPromoCode,
            wallet,
          })
          return response
        },
      })
    } else {
      handleSignIn({
        messageSignature,
        acceptMessage,
        tonProof,
        address,
        signUpPromoCode,
        wallet,
      })
    }
  }

  return {
    getMessageToWalletSign,
    handleSignMessageSuccess,
    handleGetUserSignInSettings,
  }
}
