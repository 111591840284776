import Box from 'UIKit/Box/Box'
import { ContentCalloutData } from 'components/AlchemyContent/types/callout'
import { RowCenter } from 'layout/Components/Row'
import React from 'react'
import styled from 'styled-components'
import { sanitize } from 'utils/html'
import ContentBlockContainer from '../ContentBlockContainer'
import { StyledText } from '../Text'

export type ContentHeadingProps = {
  data: ContentCalloutData
}

const Callout: React.FC<ContentHeadingProps> = ({ data: { text, icon } }) => {
  return (
    <ContentBlockContainer my="4px">
      <StyledCalloutContainer>
        <Box minWidth={24}>{icon}</Box>
        <StyledText dangerouslySetInnerHTML={{ __html: sanitize(text) }} ml="8px" />
      </StyledCalloutContainer>
    </ContentBlockContainer>
  )
}

const StyledCalloutContainer = styled(RowCenter)`
  padding: 12px 20px 12px 12px;
  background-color: #2f3035;
  width: 100%;
  border-radius: 5px;
`

export default Callout
