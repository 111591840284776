import { APIEndpointEnum } from 'config/constants/server'
import {
  TournamentDetailsByGame,
  TournamentDetailsData,
  TournamentLeaderboardItem,
  TournamentLeaderboardUserInfo,
  TournamentTransaction,
  TournamentType,
} from 'config/types/tournament'
import { formatFitlerTransaction } from 'utils/transaction'
import { OtherTransactionFilters } from 'views/Profile/Transactions/types'
import GameService from './GameService'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import { BaseResponse, HunnyRequest, Paging, PagingInfo } from './types'

class TournamentService extends HunnyPlayRequest {
  public getTournamentList(): HunnyRequest<BaseResponse<TournamentType[]>> {
    const response = this._post(APIEndpointEnum.TournamentList, {
      paging: {
        limit: 1000,
      },
    })
    return response
  }

  public getTournamentListNonLogin(): HunnyRequest<BaseResponse<TournamentType[]>> {
    const response = this._post(APIEndpointEnum.TournamentListNonLogin, {
      paging: {
        limit: 1000,
      },
    })
    return response
  }

  public async getTournamentDetails(tournamentId: number): Promise<BaseResponse<TournamentDetailsData>> {
    const response: BaseResponse<TournamentDetailsData> = await this._post(APIEndpointEnum.TournamentDetails, {
      id: tournamentId,
    }).call()

    if (response.data) {
      const defaultGameResponse = await GameService.getGames(
        { gameCollectionId: response.data.collectionGameId },
        0,
        6,
        true,
        true,
      ).call()

      if (defaultGameResponse.data.items.length) {
        response.data = {
          ...response.data,
          defaultGames: defaultGameResponse.data.items,
          totalGames: defaultGameResponse.data.paging.total,
        }
      }
    }

    return response
  }

  public getTournamentLeaderBoard(
    tournamentId: number,
    paging: PagingInfo,
  ): HunnyRequest<BaseResponse<Paging<TournamentLeaderboardItem>>> {
    const response = this._post(APIEndpointEnum.TournamentLeaderBoard, {
      paging,
      tournament_id: tournamentId,
    })
    return response
  }

  public getTournamentLeaderboardUserInfo(
    tournamentId: number,
  ): HunnyRequest<BaseResponse<TournamentLeaderboardUserInfo>> {
    const response = this._post(
      APIEndpointEnum.TournamentLeaderboardUserInfo,
      {
        tournament_id: tournamentId,
      },
      {
        authRequest: true,
      },
    )

    return response
  }

  public getTop3LeaderBoard(tournamentId: number): Promise<BaseResponse<Paging<TournamentLeaderboardItem>>> {
    const response = this._request(APIEndpointEnum.TournamentLeaderBoard, {
      paging: { limit: 3, offset: 0 },
      tournament_id: tournamentId,
    })

    return response
  }

  public getTournamentTransaction(
    offset: number,
    limit: number,
    filters?: OtherTransactionFilters,
  ): HunnyRequest<BaseResponse<Paging<TournamentTransaction>>> {
    const result = this._post(
      APIEndpointEnum.TournamentTransaction,
      {
        paging: {
          offset,
          limit,
        },
        filter: formatFitlerTransaction(filters),
      },
      { authRequest: true },
    )

    return result
  }

  public joinTournament(tournamentId: number): HunnyRequest<BaseResponse<null>> {
    const result = this._post(
      APIEndpointEnum.JoinTournament,
      {
        tournament_id: tournamentId,
      },
      { authRequest: true },
    )

    return result
  }

  public getActiveTournamentByGameSlug(gameSlug: string): HunnyRequest<BaseResponse<TournamentDetailsByGame>> {
    const result = this._post(APIEndpointEnum.TournamentDetailByGameSlug, { game_slug: gameSlug })
    return result
  }
}

const instance = new TournamentService()
export default instance
