import useModal from 'hooks/useModal'
import useSignMessage from 'hooks/useSignMessage'
import { useState } from 'react'
import { useWalletInfo } from 'state/auth/hooks'
import TonConnectSignWallet from 'views/Authentication/components/TonConnectSignWalletModal'
import { WalletEnable2FASignMessageModalProps } from './WalletEnable2FASignMessageModal'

export const usePresentTonSignMessage2FA = () => {
  const { walletInfo, wallet } = useWalletInfo()
  const [onPresentTonConnectSignWallet, onDismiss] = useModal(TonConnectSignWallet, 'ton-connect-wallet-modal')
  const [signing, setSigning] = useState(false)
  const signMessage = useSignMessage(walletInfo.type)

  const handleSignWallet = async ({ messageText, onSignSuccess }: WalletEnable2FASignMessageModalProps) => {
    setSigning(true)
    onPresentTonConnectSignWallet({
      isSigning: signing,
      onWalletSigned: async () => {
        const { messageSignature, tonProof, returnedTonAddress } = await signMessage(messageText)
        if (returnedTonAddress && wallet && returnedTonAddress !== wallet.address) {
          setSigning(false)
          return
        }
        if (messageSignature || tonProof) {
          onDismiss()
          onSignSuccess({ messageSignature, tonProof })
        }
      },
    })

    setSigning(false)
  }

  return handleSignWallet
}
