import { TournamentDetailsData, TournamentIdQueryParam } from 'config/types/tournament'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import useQueryParam from 'hooks/useQueryParam'
import { useRequest } from 'hooks/useRequest'
import { useRouter } from 'hooks/useRouter'
import { useEffect, useMemo } from 'react'
import TournamentService from 'services/TournamentService'
import { useAppDispatch, useAppSelector } from 'state'
import { useToggleSidebar } from 'state/app/hooks'
import { useAuth } from 'state/auth/hooks'
import { initTournaments, updateActiveTournament, updateJoinTournamentList, updateTournamentData } from './actions'

export const useInitTournamentInfo = () => {
  const router = useRouter()
  const tournamentIdQueryUrl = Number(router.query[TournamentIdQueryParam])

  return useMemo(
    () => ({
      tournamentIdQueryUrl: Number(tournamentIdQueryUrl) || -1,
    }),
    [tournamentIdQueryUrl],
  )
}

export const useActiveTournamentId = () => {
  const { activeTournamentId } = useAppSelector((state) => state.tournament)
  return activeTournamentId
}

export const useTournamentList = () => {
  const { tournaments } = useAppSelector((state) => state.tournament)
  return useMemo(() => {
    return {
      availableTournaments: tournaments,
      activeTournaments: tournaments.filter((tournament) => new Date(tournament.expiredTime) > new Date()),
    }
  }, [tournaments])
}

export const useTournamentDetails = (): TournamentDetailsData => {
  const { tournaments } = useAppSelector((state) => state.tournament)
  const activeTournamentId = useActiveTournamentId()

  return useMemo(
    () => tournaments.find((tournament) => tournament.id === activeTournamentId)?.details || null,
    [tournaments, activeTournamentId],
  )
}

export const useActiveTournamentChanged = (callback: (updatedTournament: TournamentDetailsData) => void) => {
  const dispatch = useAppDispatch()
  const activeTournamentId = useActiveTournamentId()
  const { availableTournaments } = useTournamentList()

  useIsomorphicEffect(() => {
    const fetch = async () => {
      if (activeTournamentId === -1) return

      const tournamentDetailIsExisted = availableTournaments.find(
        (tournament) => tournament?.id === activeTournamentId,
      )?.details

      if (tournamentDetailIsExisted) {
        callback(tournamentDetailIsExisted)
        return
      }

      const response = await TournamentService.getTournamentDetails(activeTournamentId)
      if (response.data) {
        dispatch(updateTournamentData({ data: response.data }))
      }
    }

    fetch()
  }, [activeTournamentId, availableTournaments])
}

export const useInitializeTournament = () => {
  const dispatch = useAppDispatch()
  const { execute } = useRequest()
  const { isSigned } = useAuth()

  const { tournamentIdQueryUrl } = useInitTournamentInfo()
  const { availableTournaments } = useTournamentList()
  const activeTournamentId = useActiveTournamentId()
  const { isSidebarOpen } = useToggleSidebar()
  const { removeParam } = useQueryParam()

  useEffect(() => {
    if (isSidebarOpen) {
      if (activeTournamentId > 0) return
      const isTournamentUrlInList =
        availableTournaments?.findIndex((tournament) => tournament?.id === tournamentIdQueryUrl) > 0 || false
      if (tournamentIdQueryUrl <= 0) {
        if (availableTournaments.length > 0)
          dispatch(updateActiveTournament({ data: { tournamentId: availableTournaments[0].id } }))
      } else if (!isTournamentUrlInList && availableTournaments.length > 0) {
        dispatch(updateActiveTournament({ data: { tournamentId: availableTournaments[0].id } }))
      } else {
        dispatch(updateActiveTournament({ data: { tournamentId: tournamentIdQueryUrl } }))
      }
    }
  }, [activeTournamentId, tournamentIdQueryUrl, availableTournaments, isSidebarOpen])

  useEffect(() => {
    if (
      isSidebarOpen &&
      activeTournamentId > 0 &&
      availableTournaments?.findIndex((tournament) => tournament?.id === activeTournamentId) < 0
    ) {
      dispatch(updateActiveTournament({ data: { tournamentId: availableTournaments[0]?.id } }))
    }
  }, [availableTournaments, activeTournamentId, isSidebarOpen])

  useEffect(() => {
    if (activeTournamentId > 0) {
      removeParam(TournamentIdQueryParam)
    }
  }, [activeTournamentId])

  useEffect(() => {
    const fetch = async () => {
      const response = await execute(TournamentService.getTournamentListNonLogin())
      if (response.code === 'success' && response.data) {
        dispatch(initTournaments({ data: response.data }))
      }
    }

    fetch()
  }, [])

  useEffect(() => {
    if (isSigned) {
      const fetch = async () => {
        const response = await execute(TournamentService.getTournamentList())
        if (response.code === 'success' && response.data) {
          dispatch(
            updateJoinTournamentList({
              data: response.data.reduce(
                (result, tournament) => ({ ...result, [tournament.id]: tournament.isJoined }),
                {},
              ),
            }),
          )
        }
      }

      fetch()
    }
  }, [isSigned])
}
