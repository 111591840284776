import Text from 'UIKit/Text'
import { ContentHeadingData, ContentHeadingLevel } from 'components/AlchemyContent/types/heading'
import React from 'react'
import styled from 'styled-components'
import { variant } from 'styled-system'
import { sanitize } from 'utils/html'
import ContentBlockContainer from '../ContentBlockContainer'

type StyledContentHeadingProps = {
  as: string
  level: ContentHeadingLevel
}
export type ContentHeadingProps = {
  data: ContentHeadingData
}

const Heading: React.FC<ContentHeadingProps> = ({ data: { level, text } }) => {
  return (
    <ContentBlockContainer>
      <StyledHeading as={`h${level}`} level={level} dangerouslySetInnerHTML={{ __html: sanitize(text) }} />
    </ContentBlockContainer>
  )
}

const styleLevel = {
  1: {
    fontSize: '1.875em',
    marginTop: 'calc(2rem - 8px)',
  },
  2: {
    fontSize: '1.5em',
    marginTop: 'calc(1.4rem - 8px)',
  },
  3: {
    fontSize: '1.25em',
    marginTop: 'calc(1rem - 8px)',
  },
  4: {
    fontSize: '1.15em',
    marginTop: 'calc(0.8rem - 8px)',
  },
  5: {
    fontSize: '1.10em',
    marginTop: 'calc(0.8rem - 8px)',
  },
  6: {
    fontSize: '1.05em',
    marginTop: 'calc(0.8rem - 8px)',
  },
}

const StyledHeading = styled(Text)<StyledContentHeadingProps>`
  max-width: 100%;
  width: 100%;
  font-weight: 600;
  line-height: 1.3;
  font-family: inherit;
  color: inherit;
  margin-bottom: 1px;
  padding: 3px 2px;
  white-space: pre-wrap;
  word-break: break-word;
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI Variable Display', 'Segoe UI', Helvetica,
    'Apple Color Emoji', Arial, sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol' !important;

  ${variant({
    prop: 'level',
    variants: styleLevel,
  })}
`

export default Heading
