import AlchemyContent from 'components/AlchemyContent'
import { forwardRef, useMemo } from 'react'
import { BoxProps } from 'UIKit/Box/types'
import { sanitize } from 'utils/html'
import { PromotionContentCSS } from 'views/Promotions/styled'

const AlchemyDisplayContent = forwardRef<HTMLDivElement, BoxProps & { content: string }>(
  ({ content, ...props }, ref) => {
    const contentBlocks = useMemo(() => {
      try {
        return JSON.parse(content)
      } catch (error) {
        return null
      }
    }, [content])

    return contentBlocks ? (
      <AlchemyContent ref={ref} blocks={contentBlocks} {...props} />
    ) : (
      <PromotionContentCSS
        className="html-container"
        ref={ref}
        {...props}
        dangerouslySetInnerHTML={{ __html: sanitize(content) }}
      />
    )
  },
)

export default AlchemyDisplayContent
