import { WalletDisconnectionError, WalletNotReadyError } from '@solana/wallet-adapter-base'
import { useWallet as useSolWallet } from '@solana/wallet-adapter-react'
import { CHAIN, toUserFriendlyAddress } from '@tonconnect/ui'
import { TonProofItemReplySuccess, useTonConnectUI } from '@tonconnect/ui-react'
import { WALLET_INFO } from 'config/constants/wallet'

import { TonConnector } from 'config/types/ton'
import { WalletType } from 'config/types/wallet'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSignAuthMessage } from 'state/auth/hooks'
import { delayed } from 'utils'
import { SentryHelper } from 'utils/sentryHelper'
import { HunnyToast } from 'utils/toastify'
import { ResourceUnavailableRpcError, UserRejectedRequestError } from 'viem'
import { ConnectorNotFoundError, useConnect, useDisconnect } from 'wagmi'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

type ConnectRequestTonAdditionalData = {
  ignoreSignInSystem?: boolean
  onTonWalletSigned?: () => void
}

export type ConnectRequestAdditionalData = ConnectRequestTonAdditionalData | any

export const useConnectWallet = (): {
  [type in WalletType]: {
    connect: (adapter: any, payload?: ConnectRequestAdditionalData) => Promise<any>
    disconnect: any
  }
} => {
  const { connectAsync: wagmiConnect, connectors } = useConnect()
  const { disconnectAsync: wagmiDisconnect } = useDisconnect()

  const { select, disconnect, connected } = useSolWallet()
  const { t } = useTranslation()

  const [tonConnectUI] = useTonConnectUI()
  const { handleSignMessageSuccess, handleGetUserSignInSettings, getMessageToWalletSign } = useSignAuthMessage()

  const handleSolWalletConnect = async (adapter: any) => {
    try {
      select(adapter.name)
      return true
    } catch (error) {
      if (error instanceof WalletNotReadyError) {
        HunnyToast.error(t('Your wallet is not ready'), null, {
          toastId: 'not-ready-wallet',
        })
      } else {
        HunnyToast.error(t('Something went wrong'), null, {
          toastId: 'reject-connect-wallet',
        })
        SentryHelper.captureFeatureClientError({
          feature: 'SolanaConnector',
          error,
          options: {
            payload: {
              adapter_name: adapter.name,
            },
          },
        })
      }

      return true
    }
  }

  const handleEVMWalletConnect = async (adapter: any) => {
    if (!adapter) return

    const connector = connectors.find((c) => c.id === adapter.id)

    if (!connector) {
      return HunnyToast.error(t('Connector is not found!'))
    }

    await handleEVMDisconnect()

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      try {
        /*
          We receive adapter rather than connector since we have two cases with the same id adapter but different configurations.
          For example, two objects with the same id 'walletConnectLegacy', but one has a QR code and the other does not.
        */
        const result = await wagmiConnect({ connector: adapter })
        return resolve(!!result?.account)
      } catch (error: any) {
        if (error instanceof ConnectorNotFoundError) {
          HunnyToast.error(t('Wallet is not found!'))
        } else if (error instanceof UserRejectedRequestError) {
          HunnyToast.warn(t('Rejected.'), t('Your wallet connection was rejected. Please try to connect again.'))
        } else if (error instanceof ResourceUnavailableRpcError || error.code === -32002) {
          HunnyToast.error(
            t('Please confirm connect wallet.'),
            t('Please check your wallet and confirm to connect HunnyPlay.'),
          )
        } else {
          if (
            adapter instanceof WalletConnectConnector &&
            // this only way to detect user close origin walletconnect modal
            error?.message !== 'Connection request reset. Please try again.'
          ) {
            handleEVMDisconnect()
            HunnyToast.error(t('Wrong chain'), t('Please switch to your selected network and connect again'))
          }
          SentryHelper.captureFeatureClientError({
            feature: 'EVM Connector',
            error,
          })
        }
      }
      resolve(false)
    })
  }

  const handleTonWalletDisconnect = async () => {
    try {
      await tonConnectUI.disconnect()
    } catch {}
  }

  const handleTonWalletConnect = async (adapter: TonConnector, data: ConnectRequestTonAdditionalData) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve) => {
      await delayed(50)
      try {
        await handleTonWalletDisconnect()

        const walletInfo = WALLET_INFO.find((w) => w.adapter.id === adapter?.id)

        if (!walletInfo) {
          resolve(null)
          HunnyToast.error(t('Not found Ton Wallet'), null, {
            toastId: 'not-found-ton-wallet',
          })
          return
        }

        const { acceptMessageText, acceptMessage, tokenToSignMessage } = await getMessageToWalletSign({
          address: '',
          walletType: WalletType.TON,
        })

        const unsubscribe = tonConnectUI.onStatusChange(async (wallet) => {
          await delayed(50)
          const address = toUserFriendlyAddress(wallet?.account?.address, wallet?.account?.chain === CHAIN.TESTNET)

          const proof: TonProofItemReplySuccess['proof'] | null =
            (wallet?.connectItems?.tonProof as TonProofItemReplySuccess)?.proof ?? null

          if (!proof || !address || !wallet) {
            handleTonWalletDisconnect()

            resolve(null)
            return
          }

          const { isRequire2FA } = await handleGetUserSignInSettings({
            address,
            network: tokenToSignMessage.network,
          })

          if (!data?.ignoreSignInSystem) {
            handleSignMessageSuccess({
              acceptMessage,
              isRequire2FA,
              address,
              messageSignature: '',
              tonProof: {
                tonNetwork: Number(wallet.account.chain),
                stateInit: wallet.account.walletStateInit,
                proof,
              },
              wallet: walletInfo,
            })
          }

          if (data?.onTonWalletSigned) {
            data.onTonWalletSigned()
          }

          unsubscribe()
          resolve(address)
        })

        tonConnectUI.setConnectRequestParameters({
          state: 'ready',
          value: {
            tonProof: acceptMessageText,
          },
        })
        await tonConnectUI.openModal()
      } catch (error) {
        if (error instanceof WalletNotReadyError) {
          HunnyToast.error(t('Your wallet is not ready'), null, {
            toastId: 'not-ready-ton-wallet',
          })
        } else {
          HunnyToast.error(t('Something went wrong'), null, {
            toastId: 'reject-connect-ton-wallet',
          })
          SentryHelper.captureFeatureClientError({
            feature: 'TonConnector',
            error,
            options: {
              payload: {},
            },
          })
        }

        return true
      }
    })

  const handleEVMDisconnect = async () => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (key.startsWith('wc@') || key.startsWith('wagmi')) {
        localStorage.removeItem(key)
      }
    }
    await wagmiDisconnect()
  }

  const handleSOLDisconnect = async () => {
    if (connected) {
      try {
        await disconnect()
      } catch (error) {
        if (error instanceof WalletDisconnectionError)
          HunnyToast.error(error.name, error.message, {
            toastId: 'reject-disconnect-wallet',
          })
        SentryHelper.captureFeatureClientError({
          feature: 'HandleSOLDisconnect',
          error,
        })
      }
    }
  }

  return useMemo(
    () => ({
      [WalletType.EVM]: {
        connect: handleEVMWalletConnect,
        disconnect: handleEVMDisconnect,
      },
      [WalletType.SOL]: {
        connect: handleSolWalletConnect,
        disconnect: handleSOLDisconnect,
      },
      [WalletType.TON]: {
        connect: handleTonWalletConnect,
        disconnect: handleTonWalletDisconnect,
      },
    }),
    [connected, tonConnectUI, wagmiConnect, wagmiDisconnect, select, disconnect],
  )
}
