import { ContentTableData } from 'components/AlchemyContent/types/table'
import React from 'react'
import styled from 'styled-components'
import Box from 'UIKit/Box/Box'
import Grid from 'UIKit/Box/Grid'
import { sanitize } from 'utils/html'
import ContentBlockContainer from '../ContentBlockContainer'
import { StyledText } from '../Text'

export type ContentTableProps = {
  data: ContentTableData
}

const ContentTable: React.FC<ContentTableProps> = ({ data: { content, withHeadings } }) => {
  return (
    content.length && (
      <StyledContainer display="flex" width="100%" overflowY="auto" my="4px">
        <StyledTableContainer>
          {content.map((cells, index) => (
            <StyledTableRow gridTemplateColumns={content[0].map(() => 'minmax(140px, 1fr)').join(' ')}>
              {cells.map((cell) => (
                <StyledTableCell>
                  <StyledText
                    bold={index === 0 && withHeadings}
                    fontSize={index === 0 && withHeadings ? '1.1em !important' : '1em !important'}
                    wordBreak="normal"
                    dangerouslySetInnerHTML={{ __html: sanitize(cell) }}
                  />
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </StyledTableContainer>
      </StyledContainer>
    )
  )
}

const StyledTableContainer = styled(Box)`
  border: 1px solid rgba(255, 255, 255, 0.13);
  &:last-child {
    border-bottom: none;
  }
  margin: auto;
`
const StyledContainer = styled(ContentBlockContainer)`
  padding: 12px 2px;
  a {
    text-decoration: none !important;
  }
`

const StyledTableRow = styled(Grid)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.13);

  > :last-child {
    border-right: none;
  }
`

const StyledTableCell = styled(Box)`
  border-right: 1px solid rgba(255, 255, 255, 0.13);
  padding: 6px 12px;
  min-width: 140px;

  * {
    min-width: calc(140px - 24px);
  }
`

export default ContentTable
