import { useRouter } from 'hooks/useRouter'

const useQueryParam = () => {
  const router = useRouter()

  function paramsToObject(entries: URLSearchParams) {
    try {
      if (!entries || !((entries as any)?.size > 0)) return entries
      const result = {}
      for (const [key, value] of entries) {
        try {
          result[key] = value.split(',')
        } catch {
          result[key] = value
        }
      }
      return result
    } catch {
      return entries
    }
  }

  const removeParam = async (param: string) => {
    const { pathname, query } = router
    const params = new URLSearchParams(query as any)
    params.delete(param)
    await router.replace({ pathname, query: paramsToObject(params) }, undefined, { shallow: true })
  }

  const addParam = async (key: string, value: string, shallow?: boolean) => {
    const { pathname, query } = router
    const params = new URLSearchParams(query as any)
    params.set(key, value)
    await router.replace({ pathname, query: paramsToObject(params) }, undefined, {
      shallow: typeof shallow !== 'undefined' ? shallow : true,
    })
  }

  return {
    removeParam,
    addParam,
  }
}

export default useQueryParam
