import Flex from 'UIKit/Box/Flex'
import Modal, { ModalBody, ModalProps } from 'UIKit/Modal'
import { UserBonus, WageringConditionBonus } from 'config/types/bonus/userBonus'

import Box from 'UIKit/Box/Box'
import Button from 'UIKit/Button'
import Image from 'UIKit/Image/Image'
import Text from 'UIKit/Text/Text'
import { RouteConfig } from 'config/constants/route'
import { USD_CURRENCY } from 'config/constants/tokens'
import { Token } from 'config/types'
import { BonusStep } from 'config/types/bonus'
import { useCountDown } from 'hooks/useCountdown'
import { useCloseAllModal } from 'hooks/useModal'
import { useRouter } from 'hooks/useRouter'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useTokenUsdPrice } from 'state/app/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import { colors } from 'theme/colors'
import { getFullDisplayBalance } from 'utils/formatBalance'
import BonusAction from 'views/BonusCenter/components/BonusAction'
import { buildBonusName } from 'views/BonusCenter/hooks'
import { displayCountdownTimeUnit } from 'views/Cashback/utils'

interface DepositedActiveBonusModalProps {
  bonus: UserBonus
  convertToToken: Token
}

const DepositedActiveBonusModal: React.FC<ModalProps<DepositedActiveBonusModalProps>> = ({
  onDismiss,
  data: { bonus, convertToToken },
}) => {
  const { t } = useTranslation()
  const closeModal = useCloseAllModal()
  const router = useRouter()

  const price = useTokenUsdPrice(convertToToken)

  const bonusExpireTimeRef = useCountDown(
    bonus.condition.expiryDate || 0,
    (s, m, h, d) => `
      ${displayCountdownTimeUnit(t('day'), 'Day', d)}
      <div class='colon-mark'>:</div>
      ${displayCountdownTimeUnit(t('hour'), 'Hour', h)}
      <div class='colon-mark'>:</div>
      ${displayCountdownTimeUnit(t('min'), 'Min', m)}
      <div class='colon-mark'>:</div>
      ${displayCountdownTimeUnit(t('sec'), 'Sec', s)}
    `,
    () => {},
  )

  return (
    <StyledModal onDismiss={onDismiss}>
      <StyledModalBody borderRadius="0px" height="100%">
        <Flex flexDirection="column" alignItems="center" overflow="hidden">
          <Box width="130px" height="120px">
            <Image src="/images/bonusCenter/gift-header-title.png" width={300} height={300} />
          </Box>
          <Text mt="20px" fontWeight="700" fontSize="20px">
            <Trans>Bonus Activated!</Trans>
          </Text>

          {bonus instanceof WageringConditionBonus &&
          bonus.CurrentStepInfo &&
          bonus.CurrentStepInfo.stepContent === BonusStep.Claim &&
          bonus.bonusAmount ? (
            <Box mt="24px">
              <Flex justifyContent="center" alignItems="center">
                <Box width="16px" height="16px" ml="8px">
                  <Image src={bonus.bonusAmount?.token?.logo} width={64} height={64} />
                </Box>
                <Text fontWeight="800" fontSize="16px" ml="8px" lineHeight="19.5px">
                  {getFullDisplayBalance(bonus.bonusAmount.amount, 0, 9)} {bonus.bonusAmount?.token?.name}
                </Text>
              </Flex>

              {bonus.bonusAmount.token === USD_CURRENCY && convertToToken && (
                <Flex justifyContent="center" alignItems="center" mt="12px">
                  <Text fontWeight="800" fontSize="16px" ml="8px" lineHeight="19.5px">
                    ~
                  </Text>
                  <Box width="16px" height="16px" ml="8px">
                    <Image src={convertToToken.logo} width={64} height={64} />
                  </Box>
                  <Text fontWeight="800" fontSize="16px" ml="8px" lineHeight="19.5px">
                    {getFullDisplayBalance(bonus.bonusAmount.amount.div(price), 0, 9)} {convertToToken.name}
                  </Text>
                </Flex>
              )}

              <StyledDescriptionBody justifyContent="center" mt="20px">
                <Box width="16px" height="16px" mr="5px">
                  <Icons.InfoCircleIcon width="32px" fill={colors.textDisable} />
                </Box>
                <Text fontWeight="400" fontSize="12px" color="textSubtle" lineHeight="14.63px">
                  {t(
                    'Claim the bonus to add to your real balance for games and complete the wagering within {{wagerDurationInDays}} days for withdrawal.',
                    { wagerDurationInDays: bonus.wagerDurationInDays },
                  )}
                </Text>
              </StyledDescriptionBody>
            </Box>
          ) : (
            <Box mt="24px">
              <Text
                fontSize="14px"
                fontWeight="400"
                color="textSubtle"
                textAlign="center"
                maxWidth="320px"
                marginX="auto"
                lineHeight="17.07px"
              >
                <Trans
                  i18nKey="bonuseActivatedWhenDepositDesc"
                  values={{ bonusName: buildBonusName(t, bonus.info?.name)[0] }}
                  components={{
                    highlight_tag: <Text color="text" bold fontSize="inherit" display="inline !important" />,
                  }}
                />
              </Text>
            </Box>
          )}
        </Flex>
        {bonus instanceof WageringConditionBonus &&
        bonus.CurrentStepInfo &&
        bonus.CurrentStepInfo.stepContent === BonusStep.Claim &&
        bonus.bonusAmount ? (
          <>
            <StyledButtonAction
              trackingModule="finishFreeSpinModal"
              bonus={bonus}
              swapToToken={{
                token: convertToToken,
                amount: bonus.bonusAmount.amount.div(price),
              }}
              onBonusSubmitted={() => {
                onDismiss()
              }}
            />
            <StyledCountdownContent ref={bonusExpireTimeRef} />
          </>
        ) : (
          <>
            <Button
              maxWidth="290px"
              marginX="auto"
              width="100%"
              mt="40px"
              onClick={() => {
                router.push(RouteConfig.BonusCenter)
                closeModal()
              }}
            >
              <Text small bold>
                <Trans>Check bonus</Trans>
              </Text>
            </Button>

            <Button
              variant="text"
              maxWidth="290px"
              marginX="auto"
              width="100%"
              mt="4px"
              onClick={() => {
                onDismiss()
              }}
            >
              <Text small color="textSubtle">
                <Trans>Close</Trans>
              </Text>
            </Button>
          </>
        )}
      </StyledModalBody>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  padding: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 360px !important;
    max-width: 360px !important;
  }
`

const StyledModalBody = styled(ModalBody)`
  overflow-y: unset !important;
`

const StyledDescriptionBody = styled(Flex)`
  background: rgba(255, 255, 255, 0.02);
  border-radius: 4px;
  padding: 8px;
`

const StyledButtonAction = styled(BonusAction)`
  max-width: 290px;
  margin-inline: auto;
  width: 100%;
  margin-top: 40px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
`

const StyledCountdownContent = styled(Flex)`
  color: ${({ theme: { colors } }) => colors.textAlt1};
  font-size: 12px;
  margin-top: 12px;
  margin-inline: auto;
  justify-content: center;

  div {
    width: 20px;
    text-align: center;
  }

  span {
    color: ${({ theme: { colors } }) => colors.textAlt1};
    width: 12px;
    text-align: center;
  }

  .day,
  .hour,
  .min,
  .sec {
    .value {
      color: ${({ theme: { colors } }) => colors.text};
      font-weight: bold;
      margin-bottom: 1px;
    }
  }
`
export default DepositedActiveBonusModal
