import styled from 'styled-components'
import ContentBlockContainer from '../ContentBlockContainer'

const Divider = () => {
  return (
    <ContentBlockContainer display={'flex'} alignItems={'center'} height={'13px'}>
      <StyledDivider />
    </ContentBlockContainer>
  )
}

const StyledDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.13);
  height: 1px;
  margin: 1px 0px;
`
export default Divider
