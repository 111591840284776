import { useTonConnectUI } from '@tonconnect/ui-react'
import { WalletType } from 'config/types/wallet'
import { useMemo } from 'react'
import { useAuth, useAuthBy, useWalletInfo } from 'state/auth/hooks'
import useWalletAccount from './useWalletAccount'

export const useIsTonConnectFeatured = () => {
  const { wallet } = useWalletInfo()
  return wallet.type === WalletType.TON
}

export const useTonAccount = () => {
  const accounts = useWalletAccount()
  return accounts[WalletType.TON] || ''
}

export const useDisconnectTon = () => {
  const [tonConnectUI] = useTonConnectUI()
  const handleDisconnect = async () => {
    try {
      await tonConnectUI.disconnect()
    } catch (error) {
      console.error(error)
    }
  }

  return handleDisconnect
}

export const useTonConnectStatus = (): {
  isUserConnectInvalidAddress: boolean
  isUserConnectAndSignIn: boolean
  isUserDisconnectWallet: boolean
} => {
  const tonAccount = useTonAccount()
  const { authBy } = useAuth()
  const { isAuthByWallet } = useAuthBy()
  const { wallet } = useWalletInfo()

  return useMemo(() => {
    const isMatchSystemAccount = isAuthByWallet && wallet.address === tonAccount
    const accountExised = !!tonAccount

    return {
      isUserConnectAndSignIn: isMatchSystemAccount,
      isUserConnectInvalidAddress: accountExised && !isMatchSystemAccount,
      isUserDisconnectWallet: !accountExised,
    }
  }, [tonAccount, authBy, , isAuthByWallet])
}
