import Text from 'UIKit/Text'
import { ContentTextData } from 'components/AlchemyContent/types/text'
import React from 'react'
import styled from 'styled-components'
import { sanitize } from 'utils/html'
import ContentBlockContainer from '../ContentBlockContainer'

export type ContentTextProps = {
  data: ContentTextData
}

const ContentText: React.FC<ContentTextProps> = ({ data: { text } }) => {
  return (
    <ContentBlockContainer my="1px">
      <StyledText dangerouslySetInnerHTML={{ __html: sanitize(text) }} />
    </ContentBlockContainer>
  )
}

export const StyledText = styled(Text)`
  max-width: 100%;
  width: 100%;
  color: inherit;
  white-space: pre-wrap;
  word-break: break-word;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  line-height: 1.5;
  padding: 3px 2px;
  min-height: 1em;
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI Variable Display', 'Segoe UI', Helvetica,
    'Apple Color Emoji', Arial, sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
`

export default ContentText
