import Box from 'UIKit/Box/Box'
import Grid from 'UIKit/Box/Grid'
import EmptyContent from 'UIKit/EmptyContent'
import HunnyLazyList from 'UIKit/HunnyLazyList/HunnyLazyList'
import Modal, { ModalBody, ModalHeader, ModalProps } from 'UIKit/Modal'
import Text from 'UIKit/Text/Text'
import { Token } from 'config/types'
import { Game, GamePlayAction, GameSession } from 'config/types/game'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import { useIsTelegramOrigin } from 'hooks/useTelegram'
import React, { useMemo, useState } from 'react'
import { BaseResponse, HunnyRequest, Paging } from 'services/types'
import { useAppDispatch } from 'state'
import { selectPlayBalance } from 'state/session/actions'
import styled from 'styled-components'
import base from 'theme/base'
import { delayed } from 'utils'
import GameItem from 'views/Games/components/GameItem'
import GameItemLoader from 'views/Games/components/GameItemLoader'
import FreeSpinMobileGameIframe from './FreeSpinMobileGameIframe'
import { useFreeSpinCreateGameSession } from './hooks'

interface GameListModalProps {
  titleHeader: string
  contentDescription: string
  request: (offset: number, limit: number) => HunnyRequest<BaseResponse<Paging<any>>>
  onGameSelected?: () => void
  // Note: These props are crucial for game play behavior
  // - playWithSelectedToken: Determines if free spins are available
  // - playAction: Takes priority in defining the game play action
  playWithSelectedToken?: Token
  playAction?: GamePlayAction
}

const buildLazyGameItem =
  (
    playAction: GamePlayAction,
    handlePlay?: (game: Game) => void,
    handleFetchGame?: (game: Game) => Promise<BaseResponse<GameSession>>,
  ) =>
  ({ item }) =>
    (
      <GameItem
        game={item}
        p="0px !important"
        gamePlayAction={playAction}
        handlePlayFn={handlePlay}
        handleFetchGame={handleFetchGame}
      />
    )

const GameListModal: React.FC<ModalProps<GameListModalProps>> = ({ data, onDismiss }) => {
  const { titleHeader, contentDescription, playWithSelectedToken, request, playAction, onGameSelected } = data
  const dispatch = useAppDispatch()
  const { fetchFn, gameUrl } = useFreeSpinCreateGameSession(data.playWithSelectedToken)
  const isTelegramOrigin = useIsTelegramOrigin()
  const { isMobile } = useMatchBreakpoints()
  const [isFetchMobileGameSession, setIsFetchMobileGameSession] = useState(false)

  const LazyItem = useMemo(() => {
    return buildLazyGameItem(
      playAction ?? (playWithSelectedToken ? GamePlayAction.MakeSpinWithSelectedCurrency : GamePlayAction.MakeSpin),
      () => {
        if (playWithSelectedToken) {
          dispatch(selectPlayBalance({ token: playWithSelectedToken }))
        }

        if (onGameSelected) {
          onGameSelected()
        }
      },
      async (game) => {
        if (!isMobile) return null

        if (playWithSelectedToken) {
          setIsFetchMobileGameSession(true)
          dispatch(selectPlayBalance({ token: playWithSelectedToken }))
          const gameSessionData = await fetchFn(game)
          await delayed(500)

          if (!isTelegramOrigin) {
            window.location.href = gameSessionData.gameUrl
            onDismiss()
          }

          setIsFetchMobileGameSession(false)

          return gameSessionData
        }
      },
    )
  }, [playWithSelectedToken, playAction])
  return (
    <>
      {isFetchMobileGameSession && (
        <Box
          width="100vw"
          height="100vh"
          position="fixed"
          zIndex={base.zIndices.modal}
          style={{ userSelect: 'none' }}
        />
      )}
      <StyledModal onDismiss={onDismiss}>
        <ModalHeader>
          <Box width="100%" pl={30} pr={20}>
            <Text fontSize="16px" bold>
              {titleHeader}
            </Text>
          </Box>
        </ModalHeader>

        <ModalBody p={['12px 0px !important', '', '24px 0px !important']} overflowX="hidden" position="relative">
          <Box overflow="auto">
            <Text px={['12px', '', '24px']} fontSize="12px" fontWeight="600" lineHeight="14.63px" color="textSubtle">
              {contentDescription}
            </Text>
            <Box maxHeight={'calc(var(--screen-height) - 25vh)'} pt={'24px'} px={['12px', '', '24px']}>
              <Grid
                position="relative"
                gridGap={['12px', '', '16px']}
                gridTemplateColumns={['1fr 1fr', '1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr']}
              >
                <HunnyLazyList
                  uniqueKey="code"
                  Item={LazyItem}
                  noContent={
                    <Box position="absolute" top="64px" width="100%">
                      <EmptyContent justifyContent="center" width="100%" content="We couldn't find any games" />
                    </Box>
                  }
                  Loader={
                    <>
                      <GameItemLoader p="0px !important" />
                      <GameItemLoader p="0px !important" />
                      <GameItemLoader p="0px !important" />
                    </>
                  }
                  request={request}
                  limit={20}
                />
              </Grid>
            </Box>
          </Box>
        </ModalBody>
      </StyledModal>
      {gameUrl && isTelegramOrigin && <FreeSpinMobileGameIframe iframeUrl={gameUrl} onBack={onDismiss} />}
    </>
  )
}

const StyledModal = styled(Modal)`
  ${({ theme: { mediaQueries } }) => mediaQueries.sm} {
    max-width: 448px;
    width: 100%;
    border-radius: ${({ theme }) => theme.radii.large};
  }
`

export default GameListModal
