import BigNumber from 'bignumber.js'
import { HUSD_TOKEN } from 'config/constants/tokens'
import { Token, TokenAmount } from 'config/types'
import { WageringConditionBonus } from 'config/types/bonus/userBonus'
import { useMemo } from 'react'
import { useAppDispatch } from 'state'
import { useAllTokens, useTokenUsdPrices } from 'state/app/hooks'
import { useAllWageringBonusesHaveAmount, useHUSDLockBalance } from 'state/bonus/hooks'
import { useTokenAppBalance, useTokenAppBalances } from 'state/profile/hooks'
import { selectPlayBalance } from 'state/session/actions'
import { useTokenSelected } from 'state/session/hooks'
import { isFiatCurrencyV2 } from 'utils/token'

export const useBalanceSelect = (selectedToken: Token) => {
  const HUSDLockBalance = useHUSDLockBalance()
  const prices = useTokenUsdPrices()
  const tokens = useAllTokens()

  const bonuses = useAllWageringBonusesHaveAmount()
  const tokenAmounts = useTokenAppBalances(tokens)

  const parsedBalance = useMemo(() => {
    return tokenAmounts.map((amount) => {
      const bonusAmount = bonuses
        .filter((bonus) => bonus.bonusAmount.token === amount.token)
        .reduce(
          (result, value) =>
            result.plus(
              ((value as WageringConditionBonus)?.isActiveMilestone &&
                value.milestones.reduce(
                  (result, milestone) => result.plus(!milestone.isClaimed ? milestone.bonusAmount : 0),
                  new BigNumber(0),
                )) ||
                value.bonusAmount.amount,
            ),
          new BigNumber(0),
        )

      return {
        bonus: amount.token === HUSD_TOKEN ? HUSDLockBalance : bonusAmount,
        balance: amount,
      }
    })
  }, [tokenAmounts, bonuses, HUSDLockBalance])

  const balanceOptions = useMemo(() => {
    return parsedBalance
      .filter((amount) => amount.balance.token !== selectedToken)
      .sort((amount1, amount2) => {
        const balanceInUsd1 = amount1.balance.amount.times(prices[amount1.balance.token.code])
        const balanceInUsd2 = amount2.balance.amount.times(prices[amount2.balance.token.code])
        const bonusInUsd1 = amount1.bonus.times(prices[amount1.balance.token.code])
        const bonusInUsd2 = amount2.bonus.times(prices[amount2.balance.token.code])

        if (balanceInUsd1.gt(balanceInUsd2)) return -1
        if (balanceInUsd2.gt(balanceInUsd1)) return 1
        if (bonusInUsd1.gt(bonusInUsd2)) return -1
        if (bonusInUsd2.gt(bonusInUsd1)) return 1

        if (!isFiatCurrencyV2(amount1.balance.token) && isFiatCurrencyV2(amount2.balance.token)) return -1
        if (isFiatCurrencyV2(amount1.balance.token) && !isFiatCurrencyV2(amount2.balance.token)) return 1

        if (amount2.balance.token.code > amount1.balance.token.code) return -1
        if (amount1.balance.token.code > amount2.balance.token.code) return 1

        return 0
      })
  }, [parsedBalance, selectedToken])

  const selectedBalanceOption = useMemo(() => {
    return parsedBalance.find((amount) => amount.balance.token === selectedToken)
  }, [parsedBalance, selectedToken])

  return {
    balanceOptions,
    selectedBalanceOption,
  }
}

export const useTotalBalanceInUsd = () => {
  const { balanceOptions } = useBalanceSelect(null)
  const prices = useTokenUsdPrices()

  return useMemo(() => {
    return balanceOptions?.reduce((prev, curr) => {
      return prev.plus(curr.balance.amount.times(prices[curr.balance.token.code]))
    }, new BigNumber(0))
  }, [balanceOptions, prices])
}

export const useSelectedBalance = () => {
  const dispatch = useAppDispatch()

  const selectedToken = useTokenSelected()
  const selectedBalance = useTokenAppBalance(selectedToken)

  const handleSelectBalance = (amount: TokenAmount) => {
    dispatch(selectPlayBalance({ token: amount.token }))
  }

  return {
    selectedBalance,
    handleSelectBalance,
  }
}
