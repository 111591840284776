import { forwardRef } from 'react'
import styled from 'styled-components'
import Box from 'UIKit/Box/Box'
import { BoxProps } from 'UIKit/Box/types'
import ContentButton from './components/Button'
import Callout from './components/Callout'
import Divider from './components/Divider'
import Heading from './components/Heading'
import ContentImage from './components/Image'
import List from './components/List'
import Quote from './components/Quote'
import ContentTable from './components/Table'
import ContentText from './components/Text'
import { ContentBlock } from './types'
import { ListStyleEnum } from './types/list'

type AlchemyContentProps = {
  blocks: ContentBlock[]
}

const AlchemyContent = forwardRef<HTMLDivElement, BoxProps & AlchemyContentProps>(({ blocks, ...props }, ref) => {
  return (
    <StyledContainer ref={ref} {...props}>
      {blocks.map((block) => {
        switch (block.type) {
          case 'heading':
          case 'headingTwo':
          case 'headingThree':
            return <Heading data={block.data} />
          case 'paragraph':
            return <ContentText data={block.data} />
          case 'button':
            return <ContentButton data={block.data} />
          case 'table':
            return <ContentTable data={block.data} />
          case 'image':
            return <ContentImage data={block.data} />
          case 'divider':
            return <Divider />
          case 'quote':
            return <Quote data={block.data} />
          case 'callout':
            return <Callout data={block.data} />
          case 'list': {
            if (block.data.style === ListStyleEnum.Unordered) {
              return <List data={block.data} type="list" />
            }
            if (block.data.style === ListStyleEnum.Ordered) {
              return <List data={block.data} type="ordered" />
            }
            if (block.data.style === ListStyleEnum.CheckList) {
              return <List data={block.data} type="checklist" />
            }
            return
          }
          default:
            return null
        }
      })}
    </StyledContainer>
  )
})

const StyledContainer = styled(Box)`
  max-width: 100%;
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  color: #e0e0e0e0;
  font-weight: 400;

  * {
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI Variable Display', 'Segoe UI', Helvetica,
      'Apple Color Emoji', Arial, sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  }

  code {
    background-color: rgba(255, 255, 255, 0.1);
    color: #c2669a;
    border-radius: 3px;
    font-family: 'SFMono-Regular', Menlo, Consolas, 'PT Mono', 'Liberation Mono', Courier, monospace !important;
    padding: 2px;
  }

  a {
    color: rgb(255, 133, 203) !important;
    text-decoration: underline;

    &:hover {
      color: #c2669a !important;
    }
  }
`

export default AlchemyContent
