import { APIEndpointEnum } from 'config/constants/server'
import {
  MyTierInfo as MyTierInfoResponse,
  ProfileGettingStartedTaskInfo,
  ProfileStatisticBonusInfoes,
  ProfileStatisticGameInfoes,
  PublicUserProfile,
  RefUserProfile,
  TipStatistic,
  User2FASettings,
  UserSettings,
} from 'config/types/profile'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import {
  BaseResponse,
  HunnyRequest,
  Paging,
  TraditionalPaymentPayload,
  UserAvatar,
  UserDataResponse,
  VerificationResponse,
} from './types'

class ProfileService extends HunnyPlayRequest {
  public getAvatarList(offset: number, limit: number): HunnyRequest<BaseResponse<Paging<UserAvatar>>> {
    const params = { paging: { offset, limit } }
    return this._post(APIEndpointEnum.Avatar, params, { authRequest: true })
  }

  public updateProfile({
    avatarId,
    displayName,
  }: {
    avatarId: string
    displayName: string
  }): HunnyRequest<BaseResponse<null>> {
    const params = {} as any
    if (displayName) {
      params.display_name = displayName
    }
    if (avatarId) {
      params.avatar_id = Number(avatarId)
    }
    return this._post(APIEndpointEnum.UserUpdate, params, {
      excludeErrors: ['error_data_duplicate', 'error_invalid_data'],
      authRequest: true,
    })
  }

  public update2FASetting({
    isEnable2FAForLogin,
    isEnable2FAForWithdraw,
    isEnable2FAForTip,
    otpAuthCode,
  }: User2FASettings & { otpAuthCode: string }) {
    const params = {
      log_in_required: 0,
      withdrawal_required: 0,
      tip_required: 0,
    }

    if (typeof isEnable2FAForLogin === 'boolean') params.log_in_required = isEnable2FAForLogin ? 1 : -1
    if (typeof isEnable2FAForWithdraw === 'boolean') params.withdrawal_required = isEnable2FAForWithdraw ? 1 : -1
    if (typeof isEnable2FAForTip === 'boolean') params.tip_required = isEnable2FAForTip ? 1 : -1

    return this._post(
      APIEndpointEnum.UserUpdate2FASetting,
      {
        ...params,
        totp_auth_code: String(otpAuthCode),
      },
      { excludeErrors: ['error_auth_totp'], authRequest: true },
    )
  }

  public updateSetting(settings: Partial<UserSettings>) {
    enum UserRequestSettingKey {
      isHideZeroBalance = 'hide_zero_balance',
      isDisableAnimationAirdrop = 'hide_air_drop',
      isHideStatistic = 'hide_statistic',
      isHideName = 'hide_display_name',
      isDisplayInFiat = 'hide_fiat',
      selectedFiatCurrency = 'fiat_currency',
    }

    let parsedRequestKeys = {}

    if (settings !== null) {
      parsedRequestKeys = Object.keys(settings).reduce((prevKeyObj, currentKey) => {
        let value
        switch (currentKey) {
          case 'selectedFiatCurrency':
            value = settings[currentKey].code
            break
          default:
            value = settings[currentKey] ? 1 : -1
        }

        return {
          ...prevKeyObj,
          [UserRequestSettingKey[currentKey]]: value,
        }
      }, {})
    }

    return this._post(APIEndpointEnum.UserUpdateSetting, parsedRequestKeys, { authRequest: true })
  }

  public async getUserData(): Promise<BaseResponse<UserDataResponse>> {
    const result = await this._request(APIEndpointEnum.User, null, { authRequest: true })
    return result
  }

  /// TODO: 2FA : Remove this API after BE updating
  public async getUserSettingData(): Promise<BaseResponse<{ is_totp_enable: 1 }>> {
    return this._request(APIEndpointEnum.GetUser2FAStatus, null, { authRequest: true })
  }

  public getDisplayNameByReferralCode(referralCode: string): HunnyRequest<BaseResponse<string>> {
    const result = this._post(APIEndpointEnum.DisplayName, { referral_code: referralCode })
    return result
  }

  public findUserData(display_name: string): HunnyRequest<BaseResponse<RefUserProfile>> {
    const result = this._post(
      APIEndpointEnum.FindUserData,
      {
        display_name,
      },
      { authRequest: true },
    )

    return result
  }

  public getTierInfo(): HunnyRequest<BaseResponse<MyTierInfoResponse>> {
    const result = this._post(APIEndpointEnum.MyTier, null, { authRequest: true })

    return result
  }

  public getFavoriteGames(filter: { query?: string }, offset: number, limit: number) {
    const { query } = filter
    const params = { query, paging: { offset, limit } }

    const result = this._post(APIEndpointEnum.UserFavoriteGames, params, { authRequest: true })
    return result
  }

  public getRecentGames() {
    const result = this._post(APIEndpointEnum.UserRecentGames, null, { authRequest: true })
    return result
  }

  public getFavoriteGameCodes(): HunnyRequest<BaseResponse<Paging<string>>> {
    const result = this._post(
      APIEndpointEnum.UserFavoriteGameCodes,
      { paging: { offset: 0, limit: 300 } },
      { authRequest: true },
    )

    return result
  }

  public addFavoriteGame(gameCode: string): Promise<BaseResponse<any>> {
    const result = this._request(APIEndpointEnum.AddFavoriteGame, { game_code: gameCode }, { authRequest: true })
    return result
  }

  public removeFavoriteGame(gameCode: string): Promise<BaseResponse<any>> {
    const result = this._request(APIEndpointEnum.RemoveFavoriteGame, { game_code: gameCode }, { authRequest: true })
    return result
  }

  public getGettingStartedTasks(): HunnyRequest<BaseResponse<ProfileGettingStartedTaskInfo>> {
    const result = this._post(APIEndpointEnum.ProfileGettingStartedTaks, null, { authRequest: true })
    return result
  }

  public checkoutGettingStartedWelcomePack(): Promise<BaseResponse<any>> {
    const result = this._request(APIEndpointEnum.GettingStartedCheckoutWelcomePack, null, { authRequest: true })
    return result
  }

  public updateClaimGettingStartedTasks(): Promise<BaseResponse<any>> {
    const result = this._request(APIEndpointEnum.ProfileUpdateGettingStartedTask, null, { authRequest: true })
    return result
  }

  public getStatisticGameInfoes(): HunnyRequest<BaseResponse<ProfileStatisticGameInfoes>> {
    const result = this._post(APIEndpointEnum.ProfileStatisticGameInfoes, null, { authRequest: true })
    return result
  }

  public getStatisticBonusInfoes(): HunnyRequest<BaseResponse<ProfileStatisticBonusInfoes>> {
    const result = this._post(APIEndpointEnum.ProfileStatisticBonuses, null, { authRequest: true })
    return result
  }
  public getUserProfile(usercode: string): HunnyRequest<BaseResponse<PublicUserProfile>> {
    return this._post(APIEndpointEnum.UserProfile, { user_code: usercode })
  }
  public getTipStatistics(): HunnyRequest<BaseResponse<TipStatistic>> {
    return this._post(APIEndpointEnum.UserTipStatistics, null, { authRequest: true })
  }

  public getTotalWager(startTime?: number, endTime?: number): HunnyRequest<BaseResponse<number>> {
    const params =
      startTime && endTime
        ? {
            time_range: {
              from_time: Math.floor(startTime / 1000),
              to_time: Math.floor(endTime / 1000),
            },
          }
        : {}

    return this._post(APIEndpointEnum.UserTotalWager, { ...params }, { authRequest: true })
  }

  public web3UserAddEmailPrepare(email: string): HunnyRequest<BaseResponse<VerificationResponse>> {
    return this._post(
      APIEndpointEnum.Web3UserAddEmailPrepare,
      { email },
      { excludeErrors: ['error_unknown'], authRequest: true },
    )
  }

  public web3UserAddEmail(
    prepareData: VerificationResponse & TraditionalPaymentPayload & { email: string },
  ): Promise<BaseResponse<any>> {
    return this._request(
      APIEndpointEnum.Web3UserAddEmail,
      {
        Email: prepareData.email,
        verification_data: {
          expired_time: prepareData.expireTime / 1000,
          next_resent_time: prepareData.nextResentTime / 1000,
          otp: prepareData.otp,
          token: prepareData.token,
        },
      },
      { authRequest: true },
    )
  }

  public web3UserVerifyExistedEmail(email: string): HunnyRequest<BaseResponse<VerificationResponse>> {
    return this._post(
      APIEndpointEnum.Web3UserVerifyExistedEmail,
      {
        email,
      },
      { excludeErrors: ['error_data_exists', 'error_unknown'], authRequest: true },
    )
  }

  public linkTelegram({
    webAppData,
    isLoginWidget = false,
  }: {
    webAppData: string
    isLoginWidget: boolean
  }): Promise<BaseResponse<boolean>> {
    return this._request(
      APIEndpointEnum.LinkAccountTelegram,
      {
        web_app_data: webAppData,
        login_from: isLoginWidget ? 'web_widget' : 'web_app',
      },
      {
        disabledToast: true,
        authRequest: true,
      },
    )
  }

  public unLinkTelegram(): Promise<BaseResponse<boolean>> {
    return this._request(
      APIEndpointEnum.UnLinkAccountTelegram,
      {},
      {
        disabledToast: true,
        authRequest: true,
      },
    )
  }
}

const instance = new ProfileService()
export default instance
