import {
  TournamentDetailsByGame,
  TournamentDetailsData,
  TournamentLeaderboardItem,
  TournamentLeaderboardUserInfo,
  TournamentType,
} from 'config/types/tournament'
import { BaseResponse, Paging } from 'services/types'
import { parseTournamentDetailData, parseTournamentReward } from './utils'

export const TournamentListMapper = (rawResponse: string): BaseResponse<TournamentType[]> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data.items?.map((item) => ({
      name: item.name,
      id: item.id,
      minEligibleVIPLevel: item.data.user_min_level - 1,
      maxEligibleVIPLevel: item.data.user_max_level - 1,
      image: item.image_url,
      topPrizes: [parseTournamentReward(item.first_reward)],
      expiredTime: item.to_time * 1000,
      isJoined: item.joined,
      prizePool: item.data.prize_pool,
      details: null,
    })),
  }
}

export const TournamentDetailsMapper = (rawResponse: string): BaseResponse<TournamentDetailsData> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data ? parseTournamentDetailData(response.data.tournament) : null,
  }
}

export const TournamentDetailsByGameMapper = (rawResponse: string): BaseResponse<TournamentDetailsByGame> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: response.data?.tournament
      ? {
          isInGame: response.data.is_game_in_tournament || false,
          ...parseTournamentDetailData(response.data.tournament),
        }
      : null,
  }
}

export const TournamentLeaderBoardMapper = (rawResponse: string): BaseResponse<Paging<TournamentLeaderboardItem>> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,
    data: {
      paging: response.data.paging,
      items: response.data.items.map((item) => ({
        rank: item.rank,
        displayName: item.user_display_name,
        wagerAmount: item.bet_amount_usd,
        multiplier: item.max_multiplier,
        userCode: item.user_code,
      })),
    },
  }
}

export const TournamentLeaderboardUserInfoMapper = (
  rawResponse: string,
): BaseResponse<TournamentLeaderboardUserInfo> => {
  const response: BaseResponse<any> = JSON.parse(rawResponse)

  if (!response) {
    return {
      code: 'error',
      data: null,
    }
  }

  return {
    code: response.code,
    message: response.message,

    data: response.data && {
      aboveUserInfo: response.data.user_above_stats && {
        displayName: response.data.user_above_stats.user_display_name,
        rank: response.data.user_above_stats.rank,
        multiplier: response.data.user_above_stats.max_multiplier,
        wagerAmount: response.data.user_above_stats.bet_amount_usd,
        userCode: response.data.user_above_stats.user_code,
      },
      userInfo: response.data.user_stats && {
        displayName: response.data.user_stats.user_display_name,
        rank: response.data.user_stats.rank,
        multiplier: response.data.user_stats.max_multiplier,
        wagerAmount: response.data.user_stats.bet_amount_usd,
        userCode: response.data.user_stats.user_code,
      },
    },
  }
}
