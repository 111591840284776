import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Button from 'UIKit/Button'
import Image from 'UIKit/Image'
import Modal, { ModalBody, ModalProps } from 'UIKit/Modal'
import Text from 'UIKit/Text'
import TransText from 'UIKit/Text/TransText'
import { useCountDown } from 'hooks/useCountdown'
import Column from 'layout/Components/Column'
import { Trans } from 'react-i18next'
import { colors } from 'theme/colors'

const WithdrawLockingAnnounceModal: React.FC<ModalProps<{ countdownTime: number }>> = ({
  data: { countdownTime },
  onDismiss,
}) => {
  const ref = useCountDown(
    countdownTime,
    (_s, m, h, _d) => {
      return `${h} hours, ${m} minutes`
    },
    () => {
      onDismiss()
    },
    true,
  )

  return (
    <Modal onDismiss={onDismiss}>
      <ModalBody padding="24px">
        <Text fontWeight={600} fontSize="16px" letterSpacing={-0.32} lineHeight="normal">
          <Trans>Withdraw Maintenance</Trans>
        </Text>
        <Column alignItems="center" justifyContent="center">
          <Image src="/images/payment/withdraw-lock-icon.png" width={140} height={140} alt="withdraw-lock" my="24px" />

          <Column background={colors.backgroundAlt5} p="20px" alignItems="center" justifyContent="center">
            <TransText fontWeight={300} textAlign="center" fontSize="14px" letterSpacing={-0.28} color="textTertiary">
              Withdrawals are currently under scheduled maintenance to keep things secure and running smoothly. Please
              try again later!
            </TransText>

            <Flex justifyContent="center" alignItems="center" mt="20px">
              <Text fontWeight={300} fontSize="14px" color="textTertiary">
                <Trans>Expect completion in</Trans>:{' '}
              </Text>
              <Text fontWeight={600} fontSize="14px" ml="8px">
                <Box ref={ref} />
              </Text>
            </Flex>
          </Column>
        </Column>

        <Button
          width="100%"
          my="20px"
          onClick={() => {
            onDismiss()
          }}
        >
          <TransText fontSize="14px" fontWeight={600}>
            Back to HunnyPlay
          </TransText>
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default WithdrawLockingAnnounceModal
