import { ButtonProps } from 'UIKit/Button/types'
import { SETTING_SECURITY_MODAL_KEY } from 'config/constants'
import { PrepareTraditionalVerify2FAResponse } from 'config/types/auth'
import useDebounceCallback from 'hooks/useDebounceCallback'
import useModal, { useCloseById } from 'hooks/useModal'
import { useRequest } from 'hooks/useRequest'
import { PropsWithChildren, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AuthService from 'services/AuthenticationService'
import { useUserSettings } from 'state/profile/hooks'
import { HunnyToast } from 'utils/toastify'
import Enable2FAModal from './Enable2FAModal'
import { StyledButton } from './styled'

const TraditionalEnable2FAButton: React.FC<{ onDisable2FA: () => void } & ButtonProps & PropsWithChildren> = ({
  onDisable2FA,
  children,
  ...props
}) => {
  const { userSettings } = useUserSettings()
  const isActive2FA = userSettings.isActive2FA
  const [onPresentEnable2FAModal] = useModal(Enable2FAModal)
  const { execute } = useRequest()
  const debounceCallback = useDebounceCallback()
  const [storePrepareData, setStorePrepareData] = useState<PrepareTraditionalVerify2FAResponse>()
  const { t } = useTranslation()
  const [isFetching, setIsFetching] = useState(false)
  const closeModal = useCloseById()

  const handleFetchPrepareData = async () => {
    const response = await execute(AuthService.prepareTraditionalEnable2FA())
    if (response?.data) {
      setStorePrepareData(response.data)
    }
    return response
  }

  const handlePresentEnable2FAModal = useCallback(async () => {
    const response = await handleFetchPrepareData()
    if (response?.code === 'error_email_not_sent') {
      if (isFetching) {
        return
      }
      setIsFetching(true)

      debounceCallback(async () => {
        handleFetchPrepareData()
        setIsFetching(false)
      }, 35000)
      if (!storePrepareData) {
        return HunnyToast.error(t('Too many requests, please wait a moment'))
      }
    }

    onPresentEnable2FAModal(
      {
        prepareData: response?.data || storePrepareData,
        verifyFunction: async ({ authCode, authEmailCode, sessionId }) => {
          const enable2FAResponse = await AuthService.traditionalEnable2FA({
            totpAuthcode: authCode,
            sessionId,
            emailAuthCode: authEmailCode,
          })
          return enable2FAResponse
        },
        onRefetchPrepareData() {
          return handleFetchPrepareData()
        },
      },
      true,
    )

    closeModal(SETTING_SECURITY_MODAL_KEY)
    setIsFetching(false)
  }, [isFetching, storePrepareData, closeModal])

  return (
    <StyledButton
      variant={!isActive2FA ? 'primary' : 'error'}
      onClick={() => {
        if (isActive2FA) {
          onDisable2FA()
          closeModal(SETTING_SECURITY_MODAL_KEY)
        } else debounceCallback(() => handlePresentEnable2FAModal(), 250)
      }}
      {...props}
    >
      {children}
    </StyledButton>
  )
}

export default TraditionalEnable2FAButton
