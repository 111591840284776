import { getAddress, isAddress } from '@ethersproject/address'
import { PublicKey } from '@solana/web3.js'
import { Address } from '@ton/core'
import { ChainIdEnum } from 'config/constants/network'
import { UsableFiatCurrencies } from 'config/constants/tokens/fiat'
import { NetworkType, Token, TokenAmount } from 'config/types'
import { WalletType } from 'config/types/wallet'
import WAValidator from 'multicoin-address-validator'
import { getFilterScore } from 'utils'
import { isBTCChain, isSolChain, isTonChain, isTrxChain, isXrpChain } from './network'

export const isSolToken = (token: Token) => {
  return isSolChain(token.network)
}

export const isTonToken = (token: Token) => {
  return isTonChain(token.network)
}

export const isFiatCurrency = (tokenCode: string) => UsableFiatCurrencies.includes(tokenCode)

export const isFiatCurrencyV2 = (token: Token) => token.network === ChainIdEnum.FIAT

export const checkTokenEqual = (token1: Token, token2: Token): boolean => {
  return token1 && token2 && token1.code === token2.code && token1.network === token2.network
}

export const getNetworkType = (chainId: ChainIdEnum) => {
  if (isSolChain(chainId)) return NetworkType.SOLANA
  if (chainId === ChainIdEnum.HPN) return NetworkType.HPN
  if (isBTCChain(chainId)) return NetworkType.BTC
  if (isTrxChain(chainId)) return NetworkType.TRX
  if (isTonChain(chainId)) return NetworkType.TON
  if (isXrpChain(chainId)) return NetworkType.XRP
  if ([ChainIdEnum.LTC, ChainIdEnum.LTC_TESTNET].includes(chainId)) return NetworkType.LTC
  if ([ChainIdEnum.DOGE, ChainIdEnum.DOGE_TESTNET].includes(chainId)) return NetworkType.DOGE
  return NetworkType.EVM
}

export const getNetworkCodeType = (chainId: ChainIdEnum) => {
  const networkType = getNetworkType(chainId)
  switch (networkType) {
    case NetworkType.BTC:
      return 'utxo-bitcoin'
    case NetworkType.SOLANA:
      return 'solana'
    case NetworkType.TRX:
      return 'tron'
    case NetworkType.TON:
      return 'ton'
    case NetworkType.XRP:
      return 'ripple'
    case NetworkType.LTC:
      return 'utxo-ltc'
    case NetworkType.DOGE:
      return 'utxo-doge'
    default:
      return 'evm'
  }
}

export const getChainTypeByWalletType = (walletType: WalletType) => {
  switch (walletType) {
    case WalletType.SOL:
      return 'solana'
    case WalletType.TON:
      return 'ton'
    default:
      return 'evm'
  }
}

export const validateUserAddressByNetwokType = (address: string, type: NetworkType) => {
  if (!address) return false
  try {
    switch (type) {
      case NetworkType.TON:
        return Address.isFriendly(address) || Address.isAddress(address)

      case NetworkType.XRP:
        return WAValidator.validate(address, 'xrp')

      case NetworkType.LTC:
        return WAValidator.validate(address, 'ltc') || WAValidator.validate(address, 'ltc', 'testnet')

      case NetworkType.SOLANA:
        const owner = new PublicKey(address)
        return PublicKey.isOnCurve(owner.toBytes())

      case NetworkType.BTC:
        return WAValidator.validate(address, 'bitcoin')

      case NetworkType.TRX: // TODO
        if (!/T[A-Za-z1-9]{33}/.test(address)) {
          return false
        }
        return true

      case NetworkType.DOGE:
        return WAValidator.validate(address, 'doge', { networkType: 'both' })

      case NetworkType.EVM:
        if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
          return false
        }

        // Check for checksum validity
        const cleanAddress = address.toLowerCase().replace('0x', '')
        const addressWithChecksum = getAddress(cleanAddress)
        return address.toLowerCase() === addressWithChecksum.toLowerCase()
      default:
        return false
    }
  } catch {
    return false
  }
}
export const getWalletTypeByAddress = (address: string) => {
  if (isAddress(address)) {
    return WalletType.EVM
  }

  if (PublicKey.isOnCurve(new PublicKey(address).toBytes())) {
    return WalletType.SOL
  }

  if (Address.isFriendly(address) || Address.isAddress(address)) {
    return WalletType.TON
  }
  return null
}
export const getTokenName = (token: Token) => token?.name || 'Unknown'

export const filterTokenAmountSearch = (tokenAmounts: TokenAmount[], searchString: string) => {
  try {
    if (!searchString || !tokenAmounts) return tokenAmounts

    return tokenAmounts
      .map((item) => {
        const addressSearchScore = item?.token.address
          ? getFilterScore(item.token.address, searchString, { onlyGetFullyScore: true })
          : 0
        const codeSearchScore = item?.token.code ? getFilterScore(item.token.code, searchString) : 0
        const nameSearchScore = item?.token.name ? getFilterScore(item.token.name, searchString) : 0

        const score = Math.max(addressSearchScore, codeSearchScore, nameSearchScore)
        if (!score) return null
        return { ...item, priority: score }
      })
      .filter((item) => item)
      .sort((item1, item2) => item2.priority - item1.priority) as TokenAmount[]
  } catch (e) {
    console.error(e)
    return tokenAmounts
  }
}
