import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { ChainIdEnum } from 'config/constants/network'
import { USER_BANNED_ERROR_CODE } from 'config/constants/server'
import tokens from 'config/constants/tokens'
import useModal from 'hooks/useModal'
import useSignMessage from 'hooks/useSignMessage'
import { useDisconnectTon, useIsTonConnectFeatured } from 'hooks/useTonConnect'
import useWalletAccount from 'hooks/useWalletAccount'
import { useCallback, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import PaymentService from 'services/PaymentService'
import { TonProof } from 'services/types'
import { useWalletInfo } from 'state/auth/hooks'
import { useUserSettings } from 'state/profile/hooks'
import styled from 'styled-components'
import { Icons } from 'svgs'
import theme from 'theme'
import { colors } from 'theme/colors'
import Box from 'UIKit/Box/Box'
import Flex from 'UIKit/Box/Flex'
import Button from 'UIKit/Button'
import CircleLoader from 'UIKit/Loader/CircleLoader'
import Text from 'UIKit/Text'
import { formatUsername } from 'utils/stringHelper'
import { HunnyToast } from 'utils/toastify'
import { getChainTypeByWalletType } from 'utils/token'
import TonConnectSignWallet from 'views/Authentication/components/TonConnectSignWalletModal'
import ProvideTotpAuthCodeModal from 'views/Profile/Settings/components/SettingSecurity/2FA/ProvideTotpAuthCodeModal'
import { ConfirmTipButtonProps } from './TraditionalConfirmTipbutton'

const WalletConfirmTipButton: React.FC<ConfirmTipButtonProps> = ({ details, onConfirmed }) => {
  const disconnectTon = useDisconnectTon()
  const [onPresentTonConnectSignWallet, onDismissTonConnectSignWalletModal] = useModal(
    TonConnectSignWallet,
    'ton-wallet-connect-modal',
  )
  const isTonConnected = useIsTonConnectFeatured()

  const [signing, setSigning] = useState(false)
  const [submiting, setSubmiting] = useState(false)

  const { wallet, walletInfo, onConnectByWalletType, isWrongAddress } = useWalletInfo()
  const signmessage = useSignMessage(wallet.type)

  const accounts = useWalletAccount()
  const account = accounts[wallet?.type]
  const { message, receiver, amount, currency, network } = details
  const {
    userSettings: { isActive2FA, isEnable2FAForTip },
  } = useUserSettings()
  const [onPresentProvideTotpAuthCodeModal] = useModal(ProvideTotpAuthCodeModal)
  const { t } = useTranslation()

  const handleSentTip = async ({
    acceptMessage,
    messageSignature,
    totpCode,
    tonProof,
  }: {
    acceptMessage: string
    messageSignature: string
    totpCode?: string
    tonProof?: TonProof
  }) => {
    if (submiting) return
    setSubmiting(true)
    const response = await PaymentService.walletSendTip(acceptMessage, messageSignature, totpCode, tonProof)
    if (response.code === 'success' && response?.data) {
      onConfirmed(response.data)
    }
    setSubmiting(false)
    return response
  }

  const handleSignWallet = useCallback(async () => {
    setSigning(true)

    const prepareSignMesageResponse = await PaymentService.prepareWalletTip({
      amount,
      currency: tokens[ChainIdEnum[network]]?.[currency],
      message,
      uid: receiver.uid,
      type: getChainTypeByWalletType(wallet.type),
    })

    if (prepareSignMesageResponse.code === USER_BANNED_ERROR_CODE) {
      HunnyToast.error(
        t(
          'This feature has been temporarily suspended on your account. Please reach out to Customer Service for further details.',
        ),
      )
      return onConfirmed(null)
    }

    if (prepareSignMesageResponse?.data) {
      const { messageSignature, tonProof, returnedTonAddress } = await signmessage(
        prepareSignMesageResponse.data.acceptMessageText,
      )

      if (returnedTonAddress && wallet.address && returnedTonAddress !== wallet.address) {
        setSigning(false)
        return
      }

      if (messageSignature || tonProof) {
        if (isActive2FA && isEnable2FAForTip) {
          onDismissTonConnectSignWalletModal()
          onPresentProvideTotpAuthCodeModal({
            onTotpAuthCodeConfirmed: async (code) => {
              const response = handleSentTip({
                acceptMessage: prepareSignMesageResponse.data.acceptMessage,
                messageSignature,
                totpCode: code,
                tonProof,
              })
              return response
            },
          })
        } else {
          handleSentTip({
            acceptMessage: prepareSignMesageResponse.data.acceptMessage,
            messageSignature,
            tonProof,
          })
          onDismissTonConnectSignWalletModal()
        }
      }
    }

    setSigning(false)
  }, [])

  if (isTonConnected) {
    return (
      <StyledConfirmButton
        onClick={async () => {
          await disconnectTon()
          onPresentTonConnectSignWallet({ onWalletSigned: handleSignWallet, isSigning: submiting })
        }}
        disabled={signing || submiting}
      >
        {signing && (
          <Box mr="12px">
            <CircleLoader />
          </Box>
        )}

        <Text fontSize="14px" letterSpacing={-0.28} fontWeight={600}>
          <Trans>Send Tip</Trans>
        </Text>
      </StyledConfirmButton>
    )
  }

  if (!account) {
    return (
      <Button
        onClick={() => {
          onConnectByWalletType()
        }}
      >
        <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
          <Trans>Connect Wallet</Trans>
        </Text>
      </Button>
    )
  }

  if (isWrongAddress) {
    return (
      <>
        <Flex
          alignItems="center"
          border={`1px solid ${colors.warning}`}
          padding="12px"
          borderRadius={theme.radii.small}
        >
          <Box mr="12px">
            <Icons.WarningIcon width="24px" fill={colors.warning} />
          </Box>

          <Text bold fontSize="12px" color="warning" textAlign="center" lineHeight="16px">
            {t('It seems your wallet connected is active different address, please switch to account {{account}}', {
              account: formatUsername(wallet.address),
            })}
          </Text>
        </Flex>

        {walletInfo.adapter instanceof WalletConnectConnector && (
          <Button mt="12px" width="100%" onClick={onConnectByWalletType}>
            <Text bold fontSize="14px" color={submiting ? 'textSubtle' : 'text'}>
              <Trans>Switch account</Trans>
            </Text>
          </Button>
        )}
      </>
    )
  }

  return (
    <StyledConfirmButton onClick={handleSignWallet} disabled={signing || submiting}>
      {signing && (
        <Box mr="12px">
          <CircleLoader />
        </Box>
      )}

      <Text fontSize="14px" letterSpacing={-0.28} fontWeight={600}>
        <Trans>Send Tip</Trans>
      </Text>
    </StyledConfirmButton>
  )
}

const StyledConfirmButton = styled(Button)`
  width: 100%;
  border-radius: ${({ theme: { radii } }) => radii.tiny};
  margin-bottom: 16px;
  height: 48px !important;
`

export default WalletConfirmTipButton
