import { useWallet as useSolWallet } from '@solana/wallet-adapter-react'
import { useTonAddress } from '@tonconnect/ui-react'
import { WalletType } from 'config/types/wallet'
import useWeb3React from 'packages/wagmi/hooks/useWeb3React'
import { useMemo } from 'react'

const useWalletAccount = () => {
  const { publicKey } = useSolWallet()
  const { account } = useWeb3React()
  const tonAccount = useTonAddress()

  return useMemo(
    () => ({
      [WalletType.EVM]: account,
      [WalletType.SOL]: publicKey?.toString(),
      [WalletType.TON]: tonAccount,
    }),
    [publicKey, account, tonAccount],
  )
}

export default useWalletAccount
