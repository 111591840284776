import { ContentListData } from 'components/AlchemyContent/types/list'
import React from 'react'
import styled from 'styled-components'
import { Icons } from 'svgs'
import Box from 'UIKit/Box/Box'
import { sanitize } from 'utils/html'
import ContentBlockContainer from '../ContentBlockContainer'
import { StyledText } from '../Text'

type ContentListProps = {
  data: ContentListData
  type?: 'checklist' | 'list' | 'ordered'
}

const List: React.FC<ContentListProps> = ({ data: { items }, type }) => {
  return (
    <ContentBlockContainer p="0px 2px  !important" mt="0px  !important">
      <ListDetail data={{ items }} type={type} />
    </ContentBlockContainer>
  )
}

const ListDetail: React.FC<ContentListProps & { prefix?: string; level?: number }> = ({
  data: { items },
  prefix = '',
  type,
  level = 0,
}) => {
  return (
    <StyledUl>
      {items.map((item, index) => {
        const _prefix = type === 'ordered' && prefix ? `${prefix}${index + 1}.` : `${index + 1}.`
        return (
          <>
            <StyledItem key={index}>
              <StyledPrefixContainer
                $prefix={_prefix}
                className={
                  type === 'list'
                    ? 'li-bullet'
                    : type === 'checklist'
                    ? `li-checklist ${item.meta?.checked ? 'checked' : ''}`
                    : 'li-number'
                }
                width={
                  type === 'ordered'
                    ? `calc(24px + ${level && `${level * 4}px`})`
                    : type == 'checklist'
                    ? '32px'
                    : '24px'
                }
              >
                <div>{item.meta?.checked && <Icons.SuccessCheckIcon />}</div>
              </StyledPrefixContainer>
              <Box>
                <StyledText dangerouslySetInnerHTML={{ __html: sanitize(item.content) }} />
                {item.items.length > 0 && <ListDetail data={item} prefix={_prefix} type={type} level={level + 1} />}
              </Box>
            </StyledItem>
          </>
        )
      })}
    </StyledUl>
  )
}

const StyledItem = styled.div`
  display: flex;
`

const StyledPrefixContainer = styled(Box)<{ $prefix: string }>`
  user-select: none;
  margin-right: 2px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: calc(1.5em + 6px);

  &.li-bullet {
    div {
      padding-top: 2px;
      font-size: 1.5em;
      line-height: 1;
      margin-bottom: 0px;

      &:before {
        content: '•';
        font-family: Arial;
      }
    }
  }

  &.li-checklist {
    &.checked {
      div {
        background: ${({ theme }) => theme.colors.primary};
        border: 1px solid ${({ theme }) => theme.colors.primary};
      }
    }

    div {
      width: 16px;
      margin-top: 6px;
      height: 16px;
      border-radius: 2px;
      border: 1px solid ${({ theme }) => theme.colors.textTertiary};
      position: relative;

      svg {
        width: 12px;
        position: absolute;
        top: 1px;
        left: 1px;
        fill: ${({ theme }) => theme.colors.textTertiary};
      }
    }
  }

  &.li-number {
    div {
      color: inherit;
      color: inherit;
      font-size: inherit;
      line-height: 1.5;
      padding: 3px 2px;
      min-height: 1em;
      font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI Variable Display', 'Segoe UI', Helvetica,
        'Apple Color Emoji', Arial, sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol' !important;

      &:before {
        content: '${(props) => props.$prefix}';
      }
    }
  }
`

const StyledUl = styled.div`
  display: flex;
  flex-direction: column;
`
export default List
