import { BaseContentBlock } from './base'

export enum ListStyleEnum {
  Unordered = 'unordered',
  Ordered = 'ordered',
  CheckList = 'checklist',
}

type ListItem = {
  content: string
  meta?: {
    checked?: boolean
  }
  items: ListItem[]
}

export type ContentListData = {
  items: ListItem[]
}

export type ContentBlockList = BaseContentBlock<ContentListData & { style: ListStyleEnum }> & {
  type: 'list'
}
